import React from "react";
import HomePage from "./pages/HomePage";
import { CssVarsProvider, THEME_ID as JOY_THEME_ID } from "@mui/joy/styles";
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { joyTheme, muiDarkTheme } from "./theme";
import SearchPage from "./pages/SearchPage";
import CampaignPage from "./pages/CampaignPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/ErrorPage";
import LikedPage from "./pages/LikedPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import { AuthProvider } from "react-auth-kit";
import BuyPage from "./pages/BuyPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import AccountPage from "./pages/AccountPage";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { RequireAuth } from "react-auth-kit";
import CheckEmailPage from "./pages/CheckEmailPage";
import AlertProvider from "./contexts/AlertProvider";
import UserProvider from "./contexts/UserProvider";
import BlogPage from "./pages/BlogPage";
import BlogDirectoryPage from "./pages/BlogDirectoryPage";
import HowItWorksPage from "./pages/HowItWorksPage";
import MangaPage from "./pages/MangaPage";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomePage />,
    },
    {
      path: "/search-page",
      element: <SearchPage />,
    },
    {
      path: "/campaign-page/:id/:title?",
      element: <CampaignPage />,
    },
    {
      path: "/signin-page",
      element: <SignInPage />,
    },
    {
      path: "/signup-page",
      element: <SignUpPage />,
    },
    {
      path: "/check-email-page",
      element: <CheckEmailPage />,
    },
    {
      path: "/liked-page",
      element: (
        <RequireAuth loginPath={"/signin-page"}>
          <LikedPage />
        </RequireAuth>
      ),
    },
    {
      path: "/signup-verify/:token",
      element: <VerifyEmailPage />,
    },
    {
      path: "/buy-page",
      element: <BuyPage />,
    },
    {
      path: "/password-reset-page",
      element: <PasswordResetPage />,
    },
    {
      path: "/new-password-page",
      element: (
        <RequireAuth loginPath={"/signin-page"}>
          <NewPasswordPage />
        </RequireAuth>
      ),
    },
    {
      path: "/new-password-page/:token",
      element: <NewPasswordPage />,
    },
    {
      path: "/account-page",
      element: (
        <RequireAuth loginPath={"/signin-page"}>
          <AccountPage />
        </RequireAuth>
      ),
    },
    {
      path: "/blog-page",
      element: <BlogPage />,
    },
    {
      path: "/blog-directory-page",
      element: <BlogDirectoryPage />,
    },
    {
      path: "/how-it-works-page",
      element: <HowItWorksPage />,
    },
    {
      path: "/manga-page/:id/:title?",
      element: <MangaPage />,
    },
    {
      path: "*",
      element: <ErrorPage />,
    },
  ]);

  return (
    <GoogleOAuthProvider clientId="944541287694-6r385espr9vijlrmb6gbllcefmkajofd.apps.googleusercontent.com">
      <React.StrictMode>
        <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: muiDarkTheme }}>
          <CssVarsProvider
            theme={{ [JOY_THEME_ID]: joyTheme }}
            defaultMode={"dark"}
          >
            <AuthProvider
              authType="cookie"
              authName="_auth"
              cookieDomain={window.location.hostname}
              cookieSecure={window.location.protocol === "https:"}
            >
              <AlertProvider>
                <UserProvider>
                  <RouterProvider router={router} />
                </UserProvider>
              </AlertProvider>
            </AuthProvider>
          </CssVarsProvider>
        </MaterialCssVarsProvider>
      </React.StrictMode>
    </GoogleOAuthProvider>
  );
}
