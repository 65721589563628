import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import Footer from "../blocks/Footer";
import { useTheme } from "@mui/joy/styles";

export default function BlogPage() {
  const theme = useTheme();

  return (
    <div
      className="vertical-box"
      style={{
        minHeight: "100vh",
        justifyContent: "space-around",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "30%",
          height: "200px",
          right: 0,
          top: 0,
          backgroundImage: `url("/images/film_horizontal.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}
      ></div>
      <ResponsiveAppBar />
      <div
        id="blog-parent"
        style={{
          flex: 1,
          color: theme.vars.palette.primary["50"],
          padding: "20px",
          paddingBottom: "0",
        }}
      >
        <h1 style={{ textAlign: "center", fontSize: "3rem" }}>
          Wish It. Flix It.
        </h1>

        <div
          id="blog-img"
          style={{
            width: "300px",
            height: "300px",
            float: "right",
            borderRadius: "20px",
            margin: "20px",
            background: `url("/images/logo.png")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        ></div>
        <p>
          Have you ever opened up a streaming platform and browsed around
          hundreds of selections only to find none that you actually want to
          watch? Ever disappointed that your favorite TV show on the streaming
          platform has been canceled for the next season? I certainly know I
          have and guessing by the fact that you’re on this site, you’ve
          probably had these issues too 😔. It seems like none of these
          streaming platforms really know (or care) what their customers
          actually want.
          <br />
          <br />
          Nowadays, the most common source for watching a movie or TV show is
          through a streaming service. Unfortunately, with so many companies now
          vying for the same customers, what was once a great solution to
          getting a wide selection of films conveniently within the comfort of
          your home, has devolved into a battle royale between streaming
          companies on who can provide the most content. While occasionally
          these companies do get things right and produce decent content, their
          focus on quantity over quality has led to the vast majority of
          streaming content being, well – lackluster.
          <br />
          <br />
          Seeing the current state of streaming services, our team at Wishflix
          wanted to create a different business model for long-form content; we
          wanted to focus on quality over quantity. And one way to produce
          content that people want is surprise, surprise – to just ask. At
          Wishflix, we want to make sure that you get to watch what they really
          wish for.
          <br />
          <br />
          At Wishflix, our production workflow works like this:
          <br />
          <ol style={{ padding: "20px" }}>
            <li>
              Aggregate data on what manga/manhwa/webtoon etc. has the most
              likes based on user likes on our site.
            </li>
            <li>
              After a certain graphic novel has reached an X amount of likes
              which would hint at its popularity, we will determine if we can
              reasonably gain the licensing rights to produce said content and
              can reasonably produce the content at a desired time frame, cost,
              and quality.
            </li>
            <li>
              If our criteria for a campaign are met, we will start an active
              campaign on our site.
            </li>
            <li>Successful campaigns will be moved to the production stage.</li>
            <li>
              Once the production of a movie or TV show finishes, we will
              release the film to those who fulfill a specific funding tier
              during the campaign stage. (Users can also purchase the film,
              although campaign supporters will have discounted prices)
            </li>
          </ol>
          <br />
          <u>Q & A</u>
          <div>
            Why exclusively graphic novels to films? Why not also novels in
            general?
            <br />
            <br />
            Ultimately, we want to expand to all sorts of content forms.
            However, given our team size and production constraints, we chose
            animes and cartoons due to their lower costs of production. It also
            doesn’t hurt that we are anime fanatics ourselves and there are so
            many great mangas out there that would do great with a potential
            film adaptation 😊. In the future, we plan to support the creation
            of all types of content.
          </div>
          <br />
          <hr />
          <br />
          <div>
            How can I support a manga’s creation beyond browsing and liking
            potential campaigns?
            <br />
            <br />
            Even if you don’t plan on supporting any of our projects in our
            crowd-funding stage, liking potential campaigns and active campaigns
            allows us to gain analytics into what our users want to watch (which
            we use to determine what to turn into an active campaign). Also,
            sharing both potential and active campaigns on our site (either
            through word-of-mouth, socials, etc.) with friends who may be
            interested helps our campaigns.
          </div>
          <br />
          <hr />
          <br />
          <div>
            What if a campaign is successful but Wishflix is unable to produce
            the film or show?
            <br />
            <br />
            We try to vet potential campaigns beforehand to determine what we
            can and cannot realistically make. However, on the off chance that
            we have an active, fully-funded campaign that cannot be produced,
            users who contributed to the fund will be 100% refunded, guaranteed.
          </div>
          <br />
          <hr />
          <br />
          <div>
            How much do animes cost to produce?
            <br />
            <br />
            It’s not cheap. One episode of an anime costs at least $150,000 to
            produce. That means a season’s worth of anime will cost between $1-2
            million.
          </div>
          <br />
          <hr />
          <br />
          <div>
            How long and what type of content will be produced by Wishflix?
            <br />
            <br />
            This will depend on the length and form of the original adaptation.
            That being said, most likely, we will create a season’s worth of
            content with at least 8 episodes.
          </div>
          <br />
          <hr />
          <br />
          <div>
            <p>How can I contact Wishflix?</p>
            <br />
            <p>support@wishflix.com</p>
          </div>
        </p>
        <div
          id="disqus_thread"
          style={{
            marginTop: "40px",
            backgroundColor: "#352F44",
            padding: "0 20px",
            borderRadius: "20px",
            boxShadow: "2px 2px 5px black",
          }}
        ></div>
        <script>
          {
            /**
             *  RECOMMENDED CONFIGURATION VARIABLES: EDIT AND UNCOMMENT THE SECTION BELOW TO INSERT DYNAMIC VALUES FROM YOUR PLATFORM OR CMS.
             *  LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT: https://disqus.com/admin/universalcode/#configuration-variables    */
            /*
              var disqus_config = function () {
              this.page.url = PAGE_URL;  // Replace PAGE_URL with your page's canonical URL variable
              this.page.identifier = PAGE_IDENTIFIER; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
              };
              */
            (function () {
              // DON'T EDIT BELOW THIS LINE
              var d = document,
                s = d.createElement("script");
              s.src = "https://wishflix.disqus.com/embed.js";
              s.setAttribute("data-timestamp", +new Date());
              (d.head || d.body).appendChild(s);
            })()
          }
        </script>
        <noscript>
          Please enable JavaScript to view the{" "}
          <a href="https://disqus.com/?ref_noscript">
            comments powered by Disqus.
          </a>
        </noscript>
      </div>

      <Footer />
    </div>
  );
}
