import "react-multi-carousel/lib/styles.css";
import GradientCover from "./GradientCover";
import React, { useEffect, useRef } from "react";

export default function CampaignCarouselBlock(props) {
  const [data, setData] = React.useState([]);
  const [isDragging, setIsDragging] = React.useState(false);
  const slider = useRef(null);
  let isDown = useRef(false);
  let startX = useRef(null);
  let scrollLeft = useRef(null);

  React.useEffect(() => {
    // props can be passed as a Promise or as array data
    if (props.promise) {
      props.promise.then((response) => {
        setData(response);
        if (response?.length > 0 && props.setIsLoading) {
          props.setIsLoading(false);
        }
      });
    }
    if (props.data) {
      setData(props.data);
      if (props.data.length > 0 && props.setIsLoading) {
        props.setIsLoading(false);
      }
    }
  }, [props.data]);

  const end = () => {
    isDown.current = false;
    setTimeout(function () {
      setIsDragging(false);
    }, 1);
  }

  const start = (e) => {
    isDown.current = true;
    startX = e.pageX || e.touches?.[0]?.pageX - slider.current?.offsetLeft;
    scrollLeft = slider.current.scrollLeft;
  }

  const move = (e) => {
    if (!isDown.current || e.clientX === 0) return;
    e.preventDefault();
    setIsDragging(true);
    const x = e.pageX || e.touches?.[0]?.pageX - slider.current?.offsetLeft;
    const dist = (x - startX);
    slider.current.scrollLeft = scrollLeft - dist;
  }

  useEffect(() => {
    if (slider && slider.current) {
      let sliderRef = slider.current;
      sliderRef.addEventListener('mousedown', start);

      sliderRef.addEventListener('mousemove', move);

      sliderRef.addEventListener('mouseleave', end);
      sliderRef.addEventListener('mouseup', end);

      return () => {
        sliderRef.removeEventListener('mousedown', start);

        sliderRef.removeEventListener('mousemove', move);

        sliderRef.removeEventListener('mouseleave', end);
        sliderRef.removeEventListener('mouseup', end);
      };
    }
  }, []);

  return (
    <div
      className="horizontal-box carousel"
      style={{
        display: "flex",
        overflowX: "auto",
        paddingLeft: "15px",
        position: "relative",
        whiteSpace: "nowrap",
        WebkitScrollbar: {
          display: 'none'
        },
        scrollbarWidth: 'none',
        msOverflowStyle: "none",
      }}
      ref={slider}
    >
      {data?.map((ele, index) => {
        return (
          <div
            className="carousel-item"
            key={index}
            style={{
              aspectRatio: 7 / 10,
              padding: "5px",
              margin: "10px 0",
              display: 'inline-block',
              userSelect: 'none'
            }}
          >
            <GradientCover data={ele} margin={0} isDragging={isDragging} />
          </div>
        );
      })}
    </div>
  );
}
