import React from "react";
import IconButton from "@mui/joy/IconButton";
import ShareIcon from "@mui/icons-material/Share";
import CardProgressBar from "../atoms/CardProgressBar";
import { useTheme } from "@mui/joy/styles";
import LikeIcon from "../atoms/LikeIcon";
import ShareModal from "./ShareModal";
import { useUserContext } from "../contexts/UserProvider";
import { Link } from "react-router-dom";

export default function CampaignCardOverlayBlock(props) {
  const theme = useTheme();
  const childRef = React.useRef();
  const { user } = useUserContext();
  const [isChildLarger, setIsChildLarger] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [liked, setLiked] = React.useState(false);
  const [data, setData] = React.useState(props.data);

  React.useEffect(() => {
    if (user.liked && data) {
      setLiked(user.liked.some((e) => e.anilist_id === data.anilist_id));
    }
  }, [user.liked, data]);

  React.useEffect(() => {
    const childElement = childRef.current;
    if (childElement) {
      const handleResize = () => {
        if (childElement.scrollWidth > childElement.offsetWidth) {
          setIsChildLarger(true);
        } else {
          setIsChildLarger(false);
        }
      };
      window.addEventListener("resize", handleResize);
      handleResize(); // Initial check

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [childRef.current]);

  return (
    <div className="vertical-box">
      <div
        className="horizontal-box"
        style={{
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >

        <div
          style={{
            width: "100%",
            overflow: "hidden",
            marginLeft: "5px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <h1
            className={isChildLarger ? "scroll-text" : ""}
            ref={childRef}
            style={{
              fontSize: "14px",
              color: theme.vars.palette.primary["50"],
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "100%",
            }}
          >
            {data.name_english ?? data.name_romaji}
          </h1>
        </div>
        <div className="vertical-box" style={{ margin: "5px" }}>
          <LikeIcon
            data={data}
            setData={setData}
            override={{ height: "30px", width: "30px" }}
            liked={liked}
          />
          <IconButton
            sx={{
              "--IconButton-size": "17px",
              width: "30px",
              height: "30px",
            }}
            variant="plain"
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              setOpen(true);
            }}
          >
            <ShareIcon sx={{ color: theme.vars.palette.primary["50"] }} />
          </IconButton>
        </div>
      </div>
      <CardProgressBar likes={data.likes ?? 0} />
      <ShareModal
        open={open}
        setOpen={setOpen}
        value={`${window.location}/manga-page/${data.anilist_id}/${data.name_english ?? data.name_romaji}`}
      />
    </div>
  );
}
