import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import { useTheme } from "@mui/joy/styles";
import PasswordResetBlock from "../blocks/PasswordResetBlock";
import AlertBlock from "../blocks/AlertBlock";
import { useAlertContext } from "../contexts/AlertProvider";

export default function PasswordResetPage() {
  const theme = useTheme();
  const {alertState} = useAlertContext();
  return (
    <div
      className="vertical-box"
      style={{
        minHeight: "100vh",
        backgroundColor: theme.vars.palette.primary["200"],
      }}
    >
      <ResponsiveAppBar />
      <PasswordResetBlock />
      <AlertBlock visibility={alertState.visibility} />
    </div>
  );
}
