import * as React from "react";
import { useTheme } from "@mui/joy/styles";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import IconButton from '@mui/material/IconButton';
import Input from "@mui/joy/Input";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff'; import { Button } from "@mui/joy";
import Snackbar from "@mui/joy/Snackbar";
import { PASSWORD_REGEX } from "../../constants/regexConstants";
import { useUserContext } from "../contexts/UserProvider";
import { ROOT_URL } from "../../constants/productionConstants";

export default function EditPasswordModal(props) {
  const { user } = useUserContext();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [verifyPassword, setVerifyPassword] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = React.useState(false);

  function verifyNewPassword() {
    return PASSWORD_REGEX.test(newPassword);
  }
  function submitPasswordChange() {
    fetch(`${ROOT_URL}/auth/check-current-password`, {
      method: 'post',
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify({ password: currentPassword, email: user.email })
    }).then((response) => response.json())
      .then((data) => {
        if (data.message === "password match") {
          if (newPassword === currentPassword) {
            setSnackbarMessage("New password can not be current password");
          } else if (!verifyNewPassword()) {
            setSnackbarMessage("New password must contain at least eight characters, one letter, one number and one special character");
          } else if (newPassword !== verifyPassword) {
            setSnackbarMessage("New passwords do not match");
          } else {
            fetch(`${ROOT_URL}/auth/set-new-password`, {
              method: 'post',
              headers: { "Content-Type": "application/json" },
              mode: "cors",
              body: JSON.stringify({ password: newPassword, email: user.email })
            }).then((response) => response.json())
              .then((data) => {
                if (data.message === "success") {
                  setSnackbarMessage("New password saved!");
                } else {
                  setSnackbarMessage("An error occured. Please try again");
                }
              }).catch((error) => {
                console.error(error);
                setSnackbarMessage("An error occured. Please try again");
              })
            setSnackbarMessage("Verifying new password...");
          }
        } else {
          setSnackbarMessage("Current password mismatch");
        }
        setOpen(true);
      })
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={props.open}
      onClose={(event) => {
        event.stopPropagation();
        props.setOpen(false);
        setOpen(false);
        setCurrentPassword("");
        setNewPassword("");
        setVerifyPassword("");
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: "400px",
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <div className="horizontal-box">
          <h2>Change Password</h2>
        </div>
        <div>
          <div>
            <br />
            <h3>Old Password</h3>
            <Input
              data-skip-inverted-colors
              placeholder="Current Password"
              type={showPassword ? "" : "password"}
              variant="outlined"
              sx={{ marginBottom: "10px", "--Input-focusedHighlight": theme.vars.palette.primary["50"] }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(e) => setCurrentPassword(e.target.value)}
              endDecorator={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div>
            <h3>New Password</h3>
            <Input
              data-skip-inverted-colors
              placeholder="New Password"
              variant="outlined"
              type={showNewPassword ? "" : "password"}
              sx={{ marginBottom: "10px", "--Input-focusedHighlight": theme.vars.palette.primary["50"] }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(e) => setNewPassword(e.target.value)}
              endDecorator={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                    edge="end"
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>

          <div>
            <h3>Confirm New Password</h3>
            <Input
              data-skip-inverted-colors
              placeholder="Confirm New Password"
              variant="outlined"
              type={showVerifyPassword ? "" : "password"}
              sx={{ marginBottom: "20px", "--Input-focusedHighlight": theme.vars.palette.primary["50"] }}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(e) => setVerifyPassword(e.target.value)}
              endDecorator={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                    edge="end"
                  >
                    {showVerifyPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
        </div>

        <Snackbar
          autoHideDuration={3000}
          open={open}
          variant="outlined"
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpen(false);
          }}
        >
          {snackbarMessage}
        </Snackbar>
        <div className="horizontal-box" style={{ justifyContent: "center" }}>
          <Button
            onClick={() => {
              submitPasswordChange()
            }}
            sx={{ width: "calc(100%)" }}
          >
            Save
          </Button>
        </div>
      </Sheet>
    </Modal>
  );
}
