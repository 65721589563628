import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import Footer from "../blocks/Footer";
import { useTheme } from "@mui/joy/styles";
import { useUserContext } from "../contexts/UserProvider";
import Button from "@mui/joy/Button";
import EditEmailModal from "../blocks/EditEmailModal";
import EditPasswordModal from "../blocks/EditPasswordModal";

export default function AccountPage() {
  const theme = useTheme();
  const { user } = useUserContext();
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openPassword, setOpenPassword] = React.useState(false);

  return (
    <div
      className="vertical-box"
      style={{
        backgroundColor: theme.vars.palette.primary["200"],
        minHeight: "100vh",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "30%",
          height: "200px",
          right: 0,
          top: 0,
          backgroundImage: `url("/images/film_horizontal.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: 0
        }}
      ></div>
      <ResponsiveAppBar />
      <div style={{ padding: "20px", minHeight: "100vh", zIndex: 1 }}>
        <h1 style={{ color: "white", marginBottom: "20px" }}>Settings</h1>
        <div style={{}}>
          <h2 style={{ color: "white", marginBottom: "10px" }}>
            Hi{" "}
            <span className="shining-text" style={{ color: "#f6cf96" }}>
              {user.email}
            </span>
          </h2>
          <hr />
          <div
            style={{
              minHeight: "60px",
              borderRadius: "10px",
              margin: "10px 0",
              padding: "10px",
              border: "1px solid white",
              boxShadow: "2px 2px 5px black",
              backgroundColor: theme.vars.palette.primary["150"],
            }}
          >
            <div
              className="horizontal-box"
              style={{ alignItems: "center", marginBottom: "5px" }}
            >
              <h3
                style={{
                  display: "inline",
                  marginRight: "10px",
                  color: "white",
                }}
              >
                Email
              </h3>
            </div>

            <p style={{ color: "white" }}>{user.email}</p>
          </div>
          <div
            style={{
              minHeight: "60px",
              borderRadius: "10px",
              margin: "10px 0",
              padding: "10px",
              border: "1px solid white",
              boxShadow: "2px 2px 5px black",
              backgroundColor: theme.vars.palette.primary["150"],
            }}
          >
            <div
              className="horizontal-box"
              style={{ alignItems: "center", marginBottom: "10px" }}
            >
              <h3
                style={{
                  display: "inline",
                  marginRight: "10px",
                  color: "white",
                }}
              >
                Password
              </h3>
              <Button
                size="sm"
                sx={{
                  backgroundColor: theme.vars.palette.primary["100"],
                  display: "block",
                  boxShadow: "2px 2px 5px black"
                }}
                onClick={() => {
                  setOpenPassword(true);
                }}
              >
                Change
              </Button>
            </div>

            <p style={{ color: "white" }}>***********</p>
          </div>
        </div>
      </div>
      <Footer />
      <EditEmailModal open={openEmail} setOpen={setOpenEmail} />
      <EditPasswordModal open={openPassword} setOpen={setOpenPassword} />
    </div>
  );
}
