import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/joy/Box";
import Toolbar from "@mui/material/Toolbar";
import MenuDropdown from "./MenuDropdown";
import Container from "@mui/joy/Container";
import Button from "@mui/joy/Button";
import UserDropdown from "./UserDropdown";
import { useTheme } from "@mui/joy/styles";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";
import { styled } from "@mui/joy";
import { useUserContext } from "../contexts/UserProvider";

const pages = {
  HowItWorks: "/how-it-works-page",
  Shop: "/buy-page",
  Liked: "/liked-page",
  Blog: "/blog-directory-page",
  SignIn: "/signin-page",
  SignUp: "/signup-page",
};

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { user } = useUserContext();
  const signedIn = useIsAuthenticated();
  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const A = styled("a")(() => ({
    textDecoration: "none",
  }));

  const theme = useTheme();
  return (
    <div className="horizontal-box" style={{ justifyContent: "center" }}>
      <AppBar
        position="static"
        style={{
          backgroundColor: "transparent",
          marginBottom: "10px",
          justifyContent: "center",
          boxShadow: "none",
          zIndex: 1,
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "flex",
                  md: "none",
                  alignItems: "center",
                },
              }}
            >
              <MenuDropdown />
              <img
                src="/images/logo.png"
                width="65"
                onClick={() => (window.location.href = "/")}
                style={{ cursor: "pointer" }}
              />
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              <img
                src="/images/logo.png"
                width="65"
                onClick={() => (window.location.href = "/")}
                style={{ cursor: "pointer" }}
              />
              <A href={pages.HowItWorks} sx={{ marginLeft: "10px" }}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: theme.vars.palette.primary["50"],
                    display: "block",
                  }}
                  variant="plain"
                >
                  <h2>How It Works</h2>
                </Button>
              </A>
              <A href={pages.Liked}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: theme.vars.palette.primary["50"],
                    display: "block",
                  }}
                  variant="plain"
                >
                  <h2>Liked</h2>
                </Button>
              </A>
              <A href={pages.Blog}>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: theme.vars.palette.primary["50"],
                    display: "block",
                  }}
                  variant="plain"
                >
                  <h2>Blog</h2>
                </Button>
              </A>
            </Box>
            <Box sx={{ flexGrow: 0, position: "relative" }}>
              {signedIn() ? (
                <UserDropdown />
              ) : (
                <div className="horizontal-box">
                  <A href={pages.SignIn}>
                    <Button
                      style={{
                        boxShadow: "2px 2px 5px black",
                        backgroundColor: theme.vars.palette.primary["100"],
                        borderRadius: "20px",
                        marginRight: "20px",
                      }}
                    >
                      Log In
                    </Button>
                  </A>
                  <A href={pages.SignUp}>
                    <Button
                      style={{
                        boxShadow: "2px 2px 5px black",
                        backgroundColor: theme.vars.palette.primary["150"],
                        borderRadius: "20px",
                      }}
                    >
                      Sign Up
                    </Button>
                  </A>
                </div>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}
export default ResponsiveAppBar;
