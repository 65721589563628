import React from "react";
import CampaignDetailsBlock from "./CampaignDetailsBlock";
import StickyDonationBlock from "./StickyDonationBlock";
import { useTheme } from "@mui/joy/styles";

export default function DesktopCampaignBlock(props) {
  const theme = useTheme();
  const parsedData = {
    image: props.data?.coverImage?.extraLarge ?? '/images/logo.png',
    title: props.data?.title?.english ?? props.data?.title?.romaji,
    hasAnime: '',
    description: props.data?.description,
  }
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1.1fr",
        gap: "20px",
        margin: "0 40px",
      }}
    >
      <div
        className="vertical-box"
        style={{ alignItems: "center", overflow: "auto" }}
      >
        <div
          className="vertical-box"
          style={{
            paddingTop: "20px",
            width: "80%",
          }}
        >
          <h1
            style={{
              color: theme.vars.palette.primary["50"],
              marginBottom: "20px",
            }}
          >
            {parsedData.title}
          </h1>
          <div style={{width: '100%', aspectRatio: 7/10}}>
            <img
              src={parsedData.image}
              style={{
                borderRadius: "20px",
                width: "100%",
                boxShadow: "2px 2px 5px black",
                backgroundSize: "contain",
              }}
            />

          </div>
        </div>
        <CampaignDetailsBlock isDetailsVisible={false} description={parsedData.description} anilistId={props.data?.id} />
      </div>
      <div
        className="vertical-box"
        style={{
          alignItems: "center",
        }}
      >
        <StickyDonationBlock anilistId={props.data?.id} title={parsedData.title} description={parsedData.description}/>
      </div>
    </div>
  );
}
