export const CHECK_EMAIL_PAGE_HTML = {
  "/auth/signup": {
    h1: "Verify Your Email",
    h2: "You will need to verify your email to complete registration",
    p: `An email has been sent to {state?.email} with a link to verify your
        account. If you have not received the email after a few minutes,
        please check your spam folder.`,
  },
  "/auth/set-new-email": {
    h1: "Verify Your Email",
    h2: "You will need to verify your email to complete registration",
    p: `An email has been sent to {state?.email} with a link to verify your
        account. If you have not received the email after a few minutes,
        please check your spam folder.`,
  },
  "/auth/forgot-password-email": {
    h1: "Check Your Email For Further Instructions",
    h2: "",
    p: `An email has been sent to {state?.email} with a link to reset your password. 
    If you have not received the email after a few minutes, please check your spam folder.`,
  },
};
