import React from "react";
import Button from "@mui/joy/Button";
import CardProgressBar from "../atoms/CardProgressBar";
import Input from "@mui/joy/Input";
import { useTheme } from "@mui/joy/styles";
import parse from "html-react-parser";
import LikeIcon from "../atoms/LikeIcon";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import { NumericFormat } from "react-number-format";
import { useUserContext } from "../contexts/UserProvider";
import { useAlertContext } from "../contexts/AlertProvider";

const NumericFormatAdapter = React.forwardRef(function NumericFormatAdapter(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
      allowLeadingZeros={false}
      decimalScale={0}
    />
  );
});

export default function StickyDonationBlock(props) {
  

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [clicked, setClicked] = React.useState(null);
  const [customInput, setCustomInput] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);

  const { user, setUser } = useUserContext();
  const { setAlertState } = useAlertContext();
  const setDonations = [10, 25, 50];

  function selectButtonAmount(amount) {
    if (amount === clicked) {
      setClicked(null);
    } else {
      setClicked(amount);
    }
    setCustomInput("");
  }

  function submitDonation() {
    const supportAmount = clicked ?? customInput;
    setSubmitted(true);
    if (user.coins >= supportAmount) {
      fetch(`/userInteraction/supportCampaign`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: JSON.stringify({
          email: user.email,
          transactionAmount: supportAmount,
          anilistId: props.anilistId
        }),
      }).then(response => response.json()).then(data => (
        setSubmitted(false),
        setOpen(false),
        setAlertState({html: 'success', visibility: 'visible'}),
        // TODO find better solution
        fetch(`/auth/user-coins`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          mode: "cors",
          body: JSON.stringify({
            email: user.email,
          }),
        })
          .then((response) => response.json())
          .then((data) => setUser({...user, coins: Number(data.coins)}))
          .catch((err) => console.error(err))      
      )).catch(error => console.error(error));
    } else {
      setAlertState({html: 'you broke ass bitch', visibility: 'visible'});
      setSubmitted(false);
    }
  }
  return (
    <>
      <div
        className="vertical-box"
        style={{
          position: "sticky",
          top: "20px",
          marginTop: "20px",
          backgroundColor: theme.vars.palette.primary["150"],
          boxShadow: "2px 2px 5px black",
          width: "80%",
          display: "flex",
          overflow: "auto",
          borderRadius: "20px",
          padding: "20px",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            margin: "20px",
            alignSelf: "flex-start",
            color: theme.vars.palette.primary["50"],
          }}
        >
          Details
        </h1>
        <p
          style={{
            margin: "0 20px",
            color: theme.vars.palette.primary["50"],
            maxHeight: "150px",
            overflow: "auto",
          }}
        >
          {props.description ? parse(props.description) : ""}
        </p>
        <hr
          style={{
            width: "80%",
            border: "0",
            height: "1px",
            background: "grey",
            margin: "20px",
            background: theme.vars.palette.primary["50"],
          }}
        />
        <div style={{ width: "90%", marginBottom: "35px" }}>
          <CardProgressBar progress={20} />
        </div>
        <Button
          style={{
            boxShadow: "2px 2px 5px black",
            fontFamily: "Oswald, cursive",
            fontSize: "1.25rem",
            width: "90%",
            height: "50px",
            backgroundColor: theme.vars.palette.primary["100"],
            borderRadius: "20px",
            marginBottom: "20px",
          }}
          onClick={() => setOpen(true)}
        >
          Support
        </Button>
        <div
          className="horizontal-box"
          style={{
            width: "90%",
            justifyContent: "space-between",
            marginBottom: "60px",
          }}
        >
          <Button
            style={{
              boxShadow: "2px 2px 5px black",
              fontFamily: "Oswald, cursive",
              fontSize: "1.25rem",
              width: "85%",
              height: "50px",
              backgroundColor: theme.vars.palette.primary["200"],
              borderRadius: "20px",
              marginRight: "min(20px)",
              color: theme.vars.palette.primary["50"],
            }}
          >
            Share
          </Button>
          <LikeIcon
            anilistId={props.anilistId}
            override={{
              width: "50px",
              height: "50px",
              boxShadow: "2px 2px 5px black",
              backgroundColor: theme.vars.palette.primary["200"],
              borderRadius: "20px",
            }}
          />
        </div>
      </div>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => (setOpen(false), setClicked(null), setCustomInput(''))}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
            backgroundColor: theme.vars.palette.primary["200"],
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Support {props.title}
          </Typography>
          <div
            className="horizontal-box"
            style={{
              width: "80%",
              gap: "20px",
              overflow: "auto",
              paddingBottom: "10px",
              marginBottom: "20px", 
              justifyContent: 'center',
            }}
          >
            {setDonations.map((ele, i) => {
              return (
                <Button
                  key={i}
                  disabled={submitted}
                  sx={{
                    boxShadow: "2px 2px 5px black",
                    fontFamily: "Oswald, cursive",
                    fontSize: "1.25rem",
                    flex: 1,
                    height: "50px",
                    backgroundColor: theme.vars.palette.primary["100"],
                    '&:hover' : {
                      backgroundColor: theme.vars.palette.primary["100"],
                    },
                    borderRadius: "20px",
                    opacity: clicked === ele ? 0.8 : "",
                  }}
                  onClick={() => selectButtonAmount(ele)}
                >
                  {ele} coins
                </Button>
              );
            })}
          </div>
          <div
            className="horizontal-box"
            style={{
              height: "20px",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <hr
              style={{
                width: "100%",
                margin: "10px",
                border: "0",
                height: "2px",
                background: "grey",
              }}
            />
            <h2 style={{ color: theme.vars.palette.primary["50"] }}>OR</h2>
            <hr
              style={{
                width: "100%",
                margin: "10px",
                border: "0",
                height: "2px",
                background: "grey",
              }}
            />
          </div>

          <Input
            disabled={submitted}
            placeholder="Custom Donation Quanitity"
            variant="soft"
            value={customInput}
            data-skip-inverted-colors
            onInput={(event) => (
              setCustomInput(event.target.value), setClicked(null)
            )}
            slotProps={{
              input: {
                component: NumericFormatAdapter,
              },
            }}
            sx={{
              width: "80%",
              height: "50px",
              borderRadius: "50px",
              boxShadow: "2px 2px 5px black",
              fontFamily: "Oswald, cursive",
              textAlign: "center",
              marginBottom: "20px",
              "--Input-focusedHighlight": theme.vars.palette.primary["50"],
            }}
          />
          <Button
            disabled={clicked === null && (customInput === "" || customInput == 0)}
            loading={submitted}
            style={{
              boxShadow: "2px 2px 5px black",
              fontFamily: "Oswald, cursive",
              fontSize: "1.25rem",
              flex: 1,
              height: "50px",
              backgroundColor: theme.vars.palette.primary["100"],
              borderRadius: "20px",
              color: theme.vars.palette.primary['50']
              // '&:hover' : {
              //   backgroundColor: theme.vars.palette.primary["100"],
              // },
            }}
            onClick={submitDonation}
          >
            Confirm
          </Button>
        </Sheet>
      </Modal>
    </>
  );
}
