import * as React from "react";
import Container from "@mui/joy/Container";
import Typography from "@mui/joy/Typography";
import Link from "@mui/joy/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/joy/styles";

export default function Footer() {
  const theme = useTheme();
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? "#00ADB5"
            : theme.vars.palette.primary["150"],
        p: 6,
        marginTop: "40px",
        boxShadow: "2px 2px 5px black",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              color="text.primary"
              gutterBottom
              sx={{ color: theme.vars.palette.primary["100"] }}
            >
              About Us
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ color: theme.vars.palette.primary["50"] }}
            >
              At Wishflix, we produce film adaptations for your favorite graphic
              novels.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              color="text.primary"
              gutterBottom
              sx={{ color: theme.vars.palette.primary["100"] }}
            >
              Contact Us
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ color: theme.vars.palette.primary["50"] }}
            >
              Email: support@wishflix.com
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="h6"
              color="text.primary"
              gutterBottom
              sx={{ color: theme.vars.palette.primary["100"] }}
            >
              Follow Us
            </Typography>
            <Link
              href="https://www.facebook.com/"
              sx={{ color: theme.vars.palette.primary["50"] }}
            >
              <Facebook />
            </Link>
            <Link
              href="https://www.instagram.com/"
              sx={{ pl: 1, pr: 1, color: theme.vars.palette.primary["50"] }}
            >
              <Instagram />
            </Link>
            <Link
              href="https://www.twitter.com/"
              sx={{ color: theme.vars.palette.primary["50"] }}
            >
              <Twitter />
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            sx={{ color: theme.vars.palette.primary["100"] }}
          >
            {"Copyright © "}
            <Link
              sx={{ color: theme.vars.palette.primary["100"] }}
              href="https://your-website.com/"
            >
              Wishflix
            </Link>{" "}
            {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
