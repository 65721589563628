import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import CampaignBox from "../blocks/CampaignBox";
import Footer from "../blocks/Footer";
import { useTheme } from "@mui/joy/styles";
import AlertBlock from "../blocks/AlertBlock";
import Button from "@mui/joy/Button";
import { useUserContext } from "../contexts/UserProvider";

export default function LikedPage() {
  const { user } = useUserContext();
  const theme = useTheme();
  return (
    <div
      className="vertical-box"
      style={{
        backgroundColor: theme.vars.palette.primary["200"],
        minHeight: "100vh",
        height: "auto",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "30%",
          height: "200px",
          right: 0,
          top: 0,
          backgroundImage: `url("/images/film_horizontal.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <ResponsiveAppBar />
      <h1
        style={{
          paddingLeft: "20px",
          color: theme.vars.palette.primary["50"],
          textAlign: "center",
        }}
      >
        Liked
      </h1>
      <div
        className="vertical-box"
        style={{
          flex: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            margin: "0 15px",
            width: "calc(100% - 30px)",
          }}
        >
          <CampaignBox data={user.liked} />
        </div>
        <Button
          onClick={() => {
            if (user.liked.length == 0) {
              window.location.reload();
            } else {
              // Load More Like (We may consider no pagination for first version)
            }
          }}
          size="lg"
          style={{
            marginTop: "20px",
            boxShadow: "2px 2px 5px black",
            backgroundColor: theme.vars.palette.primary["100"],
            borderRadius: "10px",
            width: user.liked.length == 0 ? "auto" : "calc(100% - 40px)",
          }}
        >
          {user.liked.length == 0 ? "Reload" : "Load More"}
        </Button>
      </div>

      <Footer />
      <AlertBlock visibility="hidden" />
    </div>
  );
}
