import React from "react";
import PillboxButton from "../atoms/PillboxButton";
import Input from "@mui/joy/Input";
import { useTheme } from "@mui/joy/styles";
import { useIsAuthenticated } from "react-auth-kit";
import useCookie from "react-use-cookie";
import { useNavigate, useHref } from "react-router-dom";
import { ROOT_URL } from "../../constants/productionConstants";
import { PASSWORD_REGEX } from "../../constants/regexConstants";

export default function NewPasswordBlock() {
  const theme = useTheme();
  const signedIn = useIsAuthenticated();
  const navigate = useNavigate();
  const href = useHref();
  const [userEmail] = useCookie("_auth_state", 0);
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [retypePassword, setRetypePassword] = React.useState("");

  const [validCurrentPassword, setValidCurrentPassword] = React.useState(false);
  const [validNewPassword, setValidNewPassword] = React.useState(false);
  const [validRetypePassword, setValidRetypePassword] = React.useState(false);

  const [isValidToken, setIsValidToken] = React.useState(null);

  function checkCurrentPassword(p) {
    setCurrentPassword(p);
    if (p !== "") {
      fetch(`${ROOT_URL}/auth/check-current-password`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: JSON.stringify({
          email: userEmail.replaceAll('"', ""),
          password: p,
        }),
      })
        .then((response) => response.json())
        .then((data) =>
          data.message === "password match"
            ? setValidCurrentPassword(true)
            : setValidCurrentPassword(false)
        )
        .catch(error => console.error(error));
    }
  }

  function checkNewPassword(p) {
    setNewPassword(p);
    if (p !== "" && p !== currentPassword) {
      setValidNewPassword(PASSWORD_REGEX.test(p));
    }
  }

  function checkRetypePassword(v) {
    setRetypePassword(v);
    setValidRetypePassword(v === newPassword);
  }

  function submitForm() {
    if (
      (signedIn() && !validCurrentPassword) ||
      !validNewPassword ||
      !validRetypePassword
    ) {
      // setAlertState({...alertState, visibility: 'visible', html: "Please give a valid input in each field"});

    } else {
      const body = JSON.stringify({
        email: signedIn() ? userEmail.replaceAll('"', "") : isValidToken,
        password: newPassword,
      });
      fetch(`${ROOT_URL}/auth/set-new-password`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: body,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "success") {
            if (signedIn()) {
              navigate("/account-page");
            } else {
              navigate("/signin-page");
            }
          }
        }).catch(error => console.error(error));
    }
  }

  React.useEffect(() => {
    if (!signedIn()) {
      fetch(`${ROOT_URL}/auth/verify-new-password-token`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: JSON.stringify({
          token: href.split("/new-password-page/")[1],
        }),
      })
        .then((response) => response.json())
        .then((data) =>
          data.message ? setIsValidToken(data.message) : setIsValidToken(false)
        ).catch(error => console.error(error));
    } else {
      setIsValidToken(true);
    }
  }, []);

  return isValidToken ? (
    <div
      className="vertical-box"
      style={{
        justifyContent: "center",
        alignItems: "center",
        top: "15%",
        position: "relative",
        flex: 1,
        padding: "40px",
      }}
    >
      <h1 style={{ color: theme.vars.palette.primary["50"], margin: "20px 0" }}>
        Set New Password
      </h1>
      <div style={{ width: "80%", maxWidth: "400px" }}>
        <Input
          placeholder="Current Password"
          variant="soft"
          type="password"
          data-skip-inverted-colors
          sx={{
            display: signedIn() ? "show" : "none",
            height: "50px",
            marginBottom: "20px",
            borderRadius: "50px",
            boxShadow: "2px 2px 5px black",
            "--Input-focusedHighlight": theme.vars.palette.primary["50"],
          }}
          onChange={(event) => checkCurrentPassword(event.target.value)}
        />
        <div
          hidden={validCurrentPassword || currentPassword.length === 0}
          style={{ color: "red", fontSize: "12px" }}
        >
          Password doesn't match current password
        </div>
        <Input
          placeholder="New Password"
          variant="soft"
          type="password"
          data-skip-inverted-colors
          sx={{
            height: "50px",
            marginBottom: "20px",
            borderRadius: "50px",
            boxShadow: "2px 2px 5px black",
            "--Input-focusedHighlight": theme.vars.palette.primary["50"],
          }}
          onChange={(event) => checkNewPassword(event.target.value)}
        />
        <div
          hidden={validNewPassword || newPassword.length === 0}
          style={{ color: "red", fontSize: "12px" }}
        >
          Password must have at least eight characters, one letter, one number
          and one special character and can't be the previous password
        </div>
        <Input
          placeholder="Retype New Password"
          variant="soft"
          type="password"
          data-skip-inverted-colors
          sx={{
            height: "50px",
            marginBottom: "20px",
            borderRadius: "50px",
            boxShadow: "2px 2px 5px black",
            "--Input-focusedHighlight": theme.vars.palette.primary["50"],
          }}
          onChange={(event) => checkRetypePassword(event.target.value)}
        />
        <div
          hidden={validRetypePassword || retypePassword.length === 0}
          style={{ color: "red", fontSize: "12px" }}
        >
          Password must match above
        </div>
        <PillboxButton onClick={() => submitForm()}>
          <h2>Save</h2>
        </PillboxButton>
      </div>
    </div>
  ) : (
    <div>invalid token</div>
  );
}
