import React from "react";

const AlertContext = React.createContext();

export default function AlertProvider({ children }) {
  const [alertState, setAlertState] = React.useState({
    visibility: "hidden",
    html: "",
  });
  return (
    <AlertContext.Provider value={{alertState, setAlertState}}>
      {children}
    </AlertContext.Provider>
  );
}

export const useAlertContext = () => {return React.useContext(AlertContext)};
