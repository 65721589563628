import React from "react";
import PillboxButton from "../atoms/PillboxButton";
import IconButton from '@mui/material/IconButton';
import Input from "@mui/joy/Input";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTheme } from "@mui/joy/styles";
import { useSignIn } from "react-auth-kit";
import { Link, useNavigate, useHref } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useUserContext } from "../contexts/UserProvider";
import { useAlertContext } from "../contexts/AlertProvider";
import { ROOT_URL } from "../../constants/productionConstants";

export default function SignInBlock(props) {
    const theme = useTheme();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const navigation = useHref();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);
  const { setAlertState } = useAlertContext();

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      fetch(`${ROOT_URL}/auth/check-google-token`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: JSON.stringify({ code: tokenResponse.code }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "success") {
            signIn({
              token: data.token,
              expiresIn: 3600,
              authState: data.email,
              tokenType: "Bearer",
            });
            window.location.href = "/";
          }
        }).catch(error => console.error(error));
    },
    flow: "auth-code",
  });

  function checkEnterKey(event) {
    if (event.key === "Enter") {
      submitForm();
    }
  }
  function submitForm() {
    if (!email || !password) {
      setAlertState({
        html: "Please enter a valid email and password",
        visibility: "visible",
      });
    } else {
      const body = JSON.stringify({
        email: email,
        password: password,
      });
      fetch(`${ROOT_URL}/auth/signin`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: body,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "Access approved") {
            signIn({
              token: data.token,
              expiresIn: 3600,
              authState: email,
              tokenType: "Bearer",
            });
            setInvalid(false);
            window.location.href = "/";
          } else {
            setInvalid(true);
          }
        }).catch(error => console.error(error));
    }
  }

  React.useEffect(() => {
    if (user.email) {
      window.location.href = "/";
    }
  }, []);

  return (
    <div
      className="vertical-box"
      style={{
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        padding: "40px 0",
        flex: 1,
      }}
    >
      <h1 style={{ color: theme.vars.palette.primary["50"], margin: "20px 0" }}>
        Log In
      </h1>
      <div style={{ width: "80%", maxWidth: "400px" }}>
        <Input
          placeholder="Email"
          variant="soft"
          type="email"
          required
          data-skip-inverted-colors
          sx={{
            height: "50px",
            marginBottom: "20px",
            borderRadius: "50px",
            boxShadow: "2px 2px 5px black",
            fontFamily: "Oswald, cursive",
            "--Input-focusedHighlight": theme.vars.palette.primary["50"],
          }}
          onKeyDown={checkEnterKey}
          onChange={(event) => (
            setEmail(event.target.value), setInvalid(false)
          )}
        />
        <Input
          placeholder="Password"
          variant="soft"
          type={showPassword ? "" : "password"}
          required
          data-skip-inverted-colors
          sx={{
            height: "50px",
            marginBottom: "5px",
            borderRadius: "50px",
            boxShadow: "2px 2px 5px black",
            fontFamily: "Oswald, cursive",
            "--Input-focusedHighlight": theme.vars.palette.primary["50"],
          }}
          onKeyDown={checkEnterKey}
          onChange={(event) => (
            setPassword(event.target.value), setInvalid(false)
          )}
          endDecorator={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <div hidden={!invalid} style={{ color: "red", fontSize: "12px", padding: "0 10px" }}>
          Invalid username or password
        </div>
        <p
          style={{
            textAlign: "end",
            marginBottom: "30px",
            paddingRight: "10px",
            color: theme.vars.palette.primary["50"],
          }}
        >
          <a href="/password-reset-page">Forgot Password?</a>
        </p>
        
        <PillboxButton onClick={() => submitForm()}>
          <h3>Log In </h3>
        </PillboxButton>
        <p
          style={{
            marginBottom: "20px",
            color: theme.vars.palette.primary["50"],
            textAlign: "center",
          }}
        >
          Don't have an account? <a href="/signup-page">Sign Up</a>
        </p>
        <div
          className="horizontal-box"
          style={{
            height: "20px",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <hr
            style={{
              width: "100%",
              margin: "10px",
              border: "0",
              height: "1px",
              background: "grey",
            }}
          />
          <h2 style={{ color: theme.vars.palette.primary["50"] }}>OR</h2>
          <hr
            style={{
              width: "100%",
              margin: "10px",
              border: "0",
              height: "1px",
              background: "grey",
            }}
          />
        </div>
        <PillboxButton onClick={() => googleLogin()}>
          <div
            className="horizontal-box"
            style={{
              justifyContent: "space-around",
              alignItems: "center",
              height: "100%",
              color: theme.vars.palette.primary["50"],
            }}
          >
            <img
              src="images/google.png"
              alt=""
              style={{
                width: "min(calc(8px + 3vw), 25px)",
                height: "min(calc(8px + 3vw), 25px)",
                marginRight: "min(calc(4px + 3vw), 20px)",
              }}
            />
            <h3>Log In With Google</h3>
            <div
              style={{
                width: "min(calc(8px + 3vw), 25px)",
                height: "min(calc(8px + 3vw), 25px)",
                marginLeft: "min(calc(4px + 3vw), 20px)",
              }}
            ></div>
          </div>
        </PillboxButton>
      </div>
    </div>
  );
}
