import React from "react";
import Button from "@mui/joy/Button";
import { useTheme } from "@mui/joy/styles";

export default function PillboxButton({ children, ...buttonProps }) {
  const theme = useTheme();
  return (
    <Button
      style={{
        boxShadow: "2px 2px 5px black",
        fontFamily: "Oswald, cursive",
        fontSize: "1.25rem",
        width: "100%",
        height: "50px",
        backgroundColor: theme.vars.palette.primary["150"],
        borderRadius: "20px",
        marginBottom: "20px",
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  );
}
