import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import { useTheme } from "@mui/joy/styles";
import AlertBlock from "../blocks/AlertBlock";
import { Button } from "@mui/joy";
import { useLocation, useNavigate } from "react-router-dom";
import { useAlertContext } from "../contexts/AlertProvider";
import { CHECK_EMAIL_PAGE_HTML } from "../../constants/htmlConstants";
import { ROOT_URL } from "../../constants/productionConstants";

export default function CheckEmailPage() {
  const theme = useTheme();
  const { state } = useLocation();
  const navigate = useNavigate();
  const bodyHTML = CHECK_EMAIL_PAGE_HTML[state?.route];
  const { alertState, setAlertState } = useAlertContext();

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);

  window.addEventListener("resize", () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  });

  function resendEmail() {
    fetch(`${ROOT_URL}/${state.route}`, {
      headers: { "Content-Type": "application/json" },
      method: "post",
      mode: "cors",
      body: JSON.stringify({ email: state.email, password: state.password }),
    })
      .then((response) => response.json())
      .then((data) =>
        data.message === "success" || data.message === ""
          ? setAlertState({
              ...alertState,
              visibility: "visible",
              html: <p>The email has been resent to {state.email}</p>,
            })
          : setAlertState({
              ...alertState,
              visibility: "visible",
              html: <p>An unexpected error occurred. Please try again later</p>,
            })
      )
      .catch((error) => console.error(error));
  }

  return (
    <div>
      <ResponsiveAppBar />
      <div
        className="vertical-box"
        style={{
          color: theme.vars.palette.primary["50"],
          backgroundColor: theme.vars.palette.primary["200"],
          minHeight: "100vh",
          justifyContent: "center",
        }}
      >
        <div
          className="vertical-box"
          style={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1
            className="email-check-h1"
            style={{ textAlign: "center", width: "75%", fontSize: "50px" }}
          >
            {bodyHTML?.["h1"]}
          </h1>
          <h2
            className="email-check-h2"
            style={{
              color: theme.vars.palette.primary["50"],
              textAlign: "center",
              width: "75%",
              fontSize: "30px",
            }}
          >
            {bodyHTML?.["h2"]}
          </h2>
          <div
            className="horizontal-box"
            style={{
              padding: "5px 20px",
              paddingBottom: "20px",
              width: "60%",
              justifyContent: "center",
            }}
          >
            <img
              src="images/message_sent.svg"
              alt=""
              style={{
                width: "50%",
                maxWidth: "300px",
                minWidth: "200px",
              }}
            />
          </div>
          <h2
            className="email-check-h2"
            style={{
              color: theme.vars.palette.primary["50"],
              textAlign: "center",
              width: "75%",
              marginBottom: "20px",
              fontSize: "30px",
            }}
          >
            {bodyHTML?.["p"].replace("{state?.email}", state?.email)}
          </h2>
          <div className="horizontal-box" style={{ marginBottom: "60px" }}>
            <Button
              size="lg"
              style={{
                boxShadow: "2px 2px 5px black",
                backgroundColor: theme.vars.palette.primary["150"],
                marginRight: "30px",
                borderRadius: "10px",
              }}
              onClick={() => resendEmail()}
            >
              Resend Email
            </Button>
            <Button
              size="lg"
              style={{
                boxShadow: "2px 2px 5px black",
                backgroundColor: theme.vars.palette.primary["100"],
                borderRadius: "10px",
              }}
              onClick={() => navigate(-1)}
            >
              Go Back
            </Button>
          </div>
        </div>
        <AlertBlock />
      </div>
    </div>
  );
}
