import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import CampaignBox from "../blocks/CampaignBox";
import Footer from "../blocks/Footer";
import { useTheme } from "@mui/joy/styles";
import AlertBlock from "../blocks/AlertBlock";
import { useLocation } from "react-router-dom";
import SearchPageIntroBlock from "../blocks/SearchPageIntroBlock";
import { URL, SEARCH_QUERY } from "../../constants/gqlQueryConstants";
import CircularProgress from '@mui/joy/CircularProgress';

export default function SearchPage() {
  const theme = useTheme();
  const location = useLocation();
  const [search, setSearch] = React.useState("");
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const manga = decodeURI(location.search).split("=")?.[1];
    setSearch(manga ?? "");
    const variables = {
      search: manga,
      page: 1,
      perPage: 10,
    };
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query: SEARCH_QUERY,
        variables: variables,
      }),
    };

    fetch(URL, options)
      .then((response) => response.json())
      .then((data) => setData(data.data), setIsLoading(false))
      .catch((error) => console.error(error));
  }, []);
  return (
    <div
      className="vertical-box"
      style={{ backgroundColor: theme.vars.palette.primary["200"] }}
    >
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(to top, #03001C, rgba(0,0,0,0) 150px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 200px)",
          }}
        ></div>
        <div
          style={{
            background: `url("/images/wind.gif")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <ResponsiveAppBar />
          <div style={{ padding: "20px" }}>
            <SearchPageIntroBlock />
          </div>
        </div>
      </div>
      {Object.keys(data).length === 0 ? (
        <div style={{ display: isLoading ? "flex" : "none", alignItems: "center", padding: '100px 0' }} className="vertical-box">
          <CircularProgress sx={{ "--CircularProgress-progressColor": theme.vars.palette.primary["100"] }} size="lg" />
        </div>
      ) : (
        <>
          <h1
            style={{
              margin: "0 20px",
              marginTop: "-40px",
              marginBottom: "20px",
              color: theme.vars.palette.primary["50"],
              textAlign: "center",
              zIndex: 1,
            }}
          >
            Search Results for <br /> "{search}"
          </h1>
          <div style={{ margin: "0 10px" }}>
            <CampaignBox data={data?.Page?.media} />
          </div>
        </>
      )}
      <Footer />
      <AlertBlock visibility="hidden" />
    </div>
  );
}
