import React from "react";
import { useTheme } from "@mui/joy/styles";
import CardProgressBar from "../atoms/CardProgressBar";
import CampaignDetailsBlock from "./CampaignDetailsBlock";
import { Button } from "@mui/joy";
import LikeIcon from "../atoms/LikeIcon";

export default function PhoneCampaignBlock(props) {
  const theme = useTheme();
  const ref = React.useRef();
  const isVisible = useIsVisible(ref);
  const parsedData = {
    image: props.data?.coverImage?.extraLarge ?? "/images/logo.png",
    title: props.data?.title?.english ?? props.data?.title?.romaji,
    hasAnime: "",
    description: props.data?.description,
  };
  return (
    <div
      className="vertical-box"
      style={{
        backgroundColor: theme.vars.palette.primary["200"],
        color: theme.vars.palette.primary["50"],
        padding: "0 20px",
        alignItems: "center",
      }}
    >
      <div
        ref={ref}
        style={{ width: "100%", margin: "0 20px", marginBottom: "15px" }}
      >
        <div style={{ width: "100%" }}>
          <h1
            style={{
              marginBottom: "20px",
              fontSize: "min(calc(7px + 3.4vw), 30px)",
            }}
          >
            {parsedData.title}
          </h1>
          <img
            src={parsedData.image}
            alt="movie"
            style={{
              width: "100%",
              borderRadius: "20px",
              boxShadow: "2px 2px 5px black",
            }}
          />
        </div>
        <div style={{ width: "100%", margin: "20px 0" }}>
          <CardProgressBar progress={20} />
        </div>
        <Button
          style={{
            width: "100%",
            boxShadow: "2px 2px 5px black",
            fontSize: "1.25rem",
            height: "50px",
            backgroundColor: theme.vars.palette.primary["100"],
            borderRadius: "20px",
            marginBottom: "20px",
          }}
        >
          Support
        </Button>
        <div
          className="horizontal-box"
          style={{
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button
            style={{
              boxShadow: "2px 2px 5px black",
              fontSize: "1.25rem",
              width: "85%",
              height: "50px",
              backgroundColor: theme.vars.palette.primary["150"],
              borderRadius: "20px",
              marginRight: "min(20px)",
              color: theme.vars.palette.primary["50"],
            }}
          >
            Share
          </Button>

          <LikeIcon
            anilistId={props.data?.id}
            override={{
              width: "50px",
              height: "50px",
              boxShadow: "2px 2px 5px black",
              backgroundColor: theme.vars.palette.primary["150"],
              borderRadius: "20px",
            }}
          />
        </div>
      </div>
      <CampaignDetailsBlock
        isDetailsVisible={true}
        description={parsedData.description}
      />
      <div
        className="horizontal-box"
        style={{
          backgroundColor: theme.vars.palette.primary["175"],
          boxShadow: "2px 2px 5px black",
          width: "100%",
          height: "75px",
          position: "fixed",
          bottom: isVisible ? "-75px" : "0px",
          zIndex: 1,
          transition: "bottom 0.2s",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button
          style={{
            flex: 1,
            boxShadow: "2px 2px 5px black",
            fontSize: "1.25rem",
            height: "50px",
            backgroundColor: theme.vars.palette.primary["100"],
            borderRadius: "20px",
            marginLeft: "10px",
          }}
        >
          Support
        </Button>
        <Button
          style={{
            flex: 1,
            boxShadow: "2px 2px 5px black",
            fontSize: "1.25rem",
            height: "50px",
            backgroundColor: theme.vars.palette.primary["150"],
            borderRadius: "20px",
          }}
        >
          Share
        </Button>
        <LikeIcon
          anilistId={props.data?.id}
          override={{
            width: "50px",
            height: "50px",
            marginRight: "10px",
            boxShadow: "2px 2px 5px black",
            backgroundColor: theme.vars.palette.primary["100"],
            borderRadius: "20px",
          }}
        />
      </div>
    </div>
  );
}

function useIsVisible(ref) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting)
    );

    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
}
