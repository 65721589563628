import React from "react";
import { useTheme } from "@mui/joy/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { useAlertContext } from "../contexts/AlertProvider";
import { useLocation } from "react-router-dom";

export default function AlertBlock(props) {
  const theme = useTheme();
  const location = useLocation();
  const { alertState, setAlertState } = useAlertContext();
  const cssFadeInOut = {
    hidden: {
      visibility: "hidden",
      opacity: 0,
      transition: "visibility 0s 1s, opacity 0.75s linear",
    },
    visible: {
      visibility: "visible",
      opacity: 0.8,
      transition: "opacity 0.25s linear",
    },
  };

  return (
    <div
      className="vertical-box"
      style={{
        alignItems: "center",
        position: "fixed",
        maxWidth: "340px",
        width: "50%",
        height: "140px",
        color: theme.vars.palette.primary["50"],
        backgroundColor: theme.vars.palette.primary["100"],
        right: "10px",
        bottom: "10px",
        borderRadius: "7px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.vars.palette.primary["50"],
        boxShadow: "2px 2px 5px black",
        padding: "3px",
        ...cssFadeInOut[alertState.visibility],
      }}
    >
      <div
        className="horizontal-box"
        style={{
          width: "100%",
          height: "auto",
          justifyContent: "flex-end",
        }}
      >
        <IconButton
          aria-label="close alert"
          sx={{ color: theme.vars.palette.primary["50"] }}
          onClick={() => {
            setAlertState({ visibility: "hidden", html: alertState.html });
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div
        style={{
          color: theme.vars.palette.primary["50"],
          display: "flex",
          textAlign: "center",
        }}
      >
        {alertState.html}
      </div>
    </div>
  );
}
