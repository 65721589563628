import React from "react";
import { useTheme } from "@mui/joy/styles";
import { useParams, useLocation } from "react-router-dom";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import Footer from "../blocks/Footer";
import LikeIcon from "../atoms/LikeIcon";
import Button from "@mui/joy/Button";
import CampaignCarouselBlock from "../blocks/CampaignCarouselBlock";
import LinearProgressCountUp from "../atoms/LinearProgressCountUp";
import ErrorPage from "./ErrorPage";
import parse from "html-react-parser";
import {
  URL,
  CAMPAIGN_QUERY,
} from "../../constants/gqlQueryConstants";
import ShareModal from "../blocks/ShareModal";
import { useUserContext } from "../contexts/UserProvider";
import DisqusCommentsBlock from "../blocks/DisqusCommentsBlock";
import { ROOT_URL } from "../../constants/productionConstants";
import shuffleArray from "../../utils/shuffleArray";

export default function MangaPage() {
  const theme = useTheme();
  const params = useParams();
  const location = useLocation();
  const { user } = useUserContext();
  const [iconData, setIconData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [liked, setLiked] = React.useState(false);
  const [recommendations, setRecommendations] = React.useState([]);
  const NUM_CARDS = 10;

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  React.useEffect(() => {
    if (user.liked && data) {
      setLiked(user.liked.some((e) => e.anilist_id == data.id));
    }
  }, [user.liked, data]);

  React.useEffect(() => {
    let variables = {
      id: params.id,
    };
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query: CAMPAIGN_QUERY,
        variables: variables,
      }),
    };
    let newData = {};
    fetch(URL, options)
      .then((response) => response.json())
      .then((returnData) => {
        if (returnData.data.Media === null || returnData.data.errors) {
          setError(true);
        } else {
          newData = returnData.data.Media;
          setData(returnData.data.Media);
        }
      })
      .catch((error) => console.error(error));
    fetch(`${ROOT_URL}/anilist/getLikes`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        id: params.id,
      }),
    }).then(response => response.json())
      .then(res => setIconData({
        anilist_id: newData?.id,
        image_url: newData?.coverImage?.extraLarge,
        name_english: newData?.title?.english,
        name_romaji: newData?.title?.romaji,
        likes: res.data[0]?.likes ?? 0,
        // funds: data?.funds,
      }));
  }, []);

  React.useEffect(() => {
    if (loading && Object.keys(data).length > 0) {
      const recs = [];
      const shuffledRecs = shuffleArray(data?.recommendations?.edges);
      let i = 0;
      while (recs.length < NUM_CARDS && i < shuffledRecs?.length) {
        if (!recs.some(e => e?.anilist_id === shuffledRecs[i]?.node?.mediaRecommendation?.id)) {
          recs.push({
            anilist_id: shuffledRecs[i]?.node?.mediaRecommendation?.id,
            image_url: shuffledRecs[i]?.node?.mediaRecommendation?.coverImage?.extraLarge,
            name_english: shuffledRecs[i]?.node?.mediaRecommendation?.title?.english,
            name_romaji: shuffledRecs[i]?.node?.mediaRecommendation?.title?.romaji
          });
        }
        i++;
      }
      recs.forEach(async (ele, i) => {
        await fetch(`${ROOT_URL}/anilist/getLikes`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            id: ele.anilist_id,
          }),
        }).then((response) => response.json())
          .then(returnData => {
            recs[i] = { ...recs[i], likes: returnData.data[0]?.likes ?? 0 }
          })
        if (i === recs.length - 1) {
          setRecommendations(recs);
        }
      })
      setLoading(false);
    }
  }, [data]);

  const coverImage = data?.coverImage?.extraLarge ?? "/images/logo.png";
  const parsedData = {
    coverImage: coverImage,
    bannerImage: data?.bannerImage,
    title: data?.title?.english ?? data?.title?.romaji,
    hasAnime: "",
    description: data?.description,
    externalLinks: data?.externalLinks ?? [],
  };

  return error ? (
    <ErrorPage />
  ) : (
    <div className="vertical-box" style={{ minHeight: "100vh" }}>
      <div style={{ flex: "100vh" }}>
        <div
          id="banner-box"
          style={{
            height: parsedData.bannerImage == undefined ? "200px" : "400px",
            backgroundImage: `url("${parsedData.bannerImage}")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            boxShadow:
              parsedData.bannerImage == undefined ? "" : "2px 2px 5px black",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "30%",
              height: "200px",
              right: 0,
              top: 0,
              backgroundImage: `url("/images/film_horizontal.png")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              zIndex: -1,
            }}
          ></div>
          <ResponsiveAppBar />
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              background:
                parsedData.bannerImage == undefined
                  ? ""
                  : "linear-gradient(to bottom, #454545, rgba(0,0,0,0) 100px), linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0) 200px)",
            }}
          ></div>
          <div style={{ position: "relative" }}>
            <div
              id="manga-box"
              style={{
                position: "absolute",
                left: "50px",
                top: parsedData.bannerImage == undefined ? "0" : "150px",
              }}
            >
              <img
                src={parsedData.coverImage}
                style={{
                  borderRadius: "20px",
                  width: "200px",
                  boxShadow: "2px 2px 5px black",
                  objectFit: "cover",
                  aspectRatio: 7 / 10,
                  margin: "auto",
                }}
              />
              <div className="horizontal-box" id="action-bar-1">
                <div
                  className="horizontal-box"
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                >
                  <Button
                    onClick={() => {
                      setOpen(true);
                    }}
                    style={{
                      boxShadow: "2px 2px 5px black",
                      fontFamily: "Oswald, cursive",
                      fontSize: "1.25rem",
                      flex: "2",
                      height: "50px",
                      backgroundColor: theme.vars.palette.primary["100"],
                      borderRadius: "20px",
                      marginRight: "10px",
                      color: theme.vars.palette.primary["50"],
                    }}
                  >
                    Share
                  </Button>
                  <LikeIcon
                    data={iconData}
                    setData={setIconData}
                    override={{
                      width: "50px",
                      height: "50px",
                      boxShadow: "2px 2px 5px black",
                      backgroundColor: theme.vars.palette.primary["150"],
                      borderRadius: "20px",
                    }}
                    liked={liked}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{}}>
          <div
            id="action-bar-wrapper"
            style={{
              padding: "20px",
              marginLeft: "250px",
              position: "relative",
            }}
          >
            <div
              className="horizontal-box"
              style={{
                position: "absolute",
                top: parsedData.bannerImage == undefined ? "-35px" : "-60px",
                alignItems: "flex-end",
                overflow: "scroll",
                maxWidth: "calc(100vw - 210px)",
              }}
            >
              {parsedData.externalLinks.map((ele, index) => {
                return (
                  <a href={ele.url} key={index} target="_blank">
                    <Button
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        backgroundColor: ele.color ?? "lightblue",
                        boxShadow: "2px 2px 5px black",
                        marginRight: "20px",
                        marginBottom: "5px",
                        padding: 0,
                      }}
                    >
                      <div
                        style={{
                          margin: "10px",
                          width: "30px",
                          height: "30px",
                          background:
                            ele.icon == undefined
                              ? `url("/images/link.png")`
                              : `url("${ele.icon}")`,
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundColor: ele.color ?? "lightblue",
                        }}
                      ></div>
                    </Button>
                  </a>

                );
              })}
            </div>

            <div
              className="horizontal-box"
              id="action-bar-2"
              style={{
                display: "none",
                marginTop: "15px",
              }}
            >
              <div
                className="horizontal-box"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  style={{
                    boxShadow: "2px 2px 5px black",
                    fontFamily: "Oswald, cursive",
                    fontSize: "1.25rem",
                    flex: "2",
                    height: "50px",
                    backgroundColor: theme.vars.palette.primary["100"],
                    borderRadius: "20px",
                    marginRight: "10px",
                    color: theme.vars.palette.primary["50"],
                  }}
                >
                  Share
                </Button>
                <LikeIcon
                  data={iconData}
                  setData={setIconData}
                  override={{
                    width: "50px",
                    height: "50px",
                    boxShadow: "2px 2px 5px black",
                    backgroundColor: theme.vars.palette.primary["150"],
                    borderRadius: "20px",
                  }}
                  liked={liked}
                />
              </div>
            </div>
            <div id="text-box-1">
              <h1 style={{ color: theme.vars.palette.primary["50"] }}>
                {parsedData.title ?? parse("&nbsp;")}
              </h1>
              <p
                style={{
                  color: theme.vars.palette.primary["50"],
                  minHeight:
                    parsedData.bannerImage == undefined ? "150px" : "100px",
                }}
              >
                {parsedData.description ? parse(parsedData.description) : ""}
              </p>
            </div>
          </div>
        </div>
        <div style={{ padding: "0 20px" }}>
          <div style={{ width: "100%", marginBottom: "20px" }}>
            <LinearProgressCountUp likes={iconData.likes} />
          </div>
          <div id="text-box-2" style={{ display: "none" }}>
            <h1 style={{ color: theme.vars.palette.primary["50"] }}>
              {parsedData.title}
            </h1>
            <p
              style={{
                color: theme.vars.palette.primary["50"],
              }}
            >
              {parsedData.description ? parse(parsedData.description) : ""}
            </p>
          </div>
          <h1
            style={{
              margin: "10px 0",
              alignSelf: "flex-start",
              color: theme.vars.palette.primary["50"],
            }}
          >
            Comments
          </h1>
          <DisqusCommentsBlock />
        </div>
        <div
          style={{ display: recommendations.length > 0 ? "block" : "none" }}
        >
          <h1
            style={{
              paddingLeft: "20px",
              color: theme.vars.palette.primary["50"],
            }}
          >
            Similar
          </h1>
          <CampaignCarouselBlock data={recommendations} />
        </div>
      </div>

      <Footer />
      <ShareModal open={open} setOpen={setOpen} value={window.location.href} />
    </div>
  );
}
