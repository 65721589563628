import React from "react";
import GradientCover from "./GradientCover";
import NoItemsBlock from "./NoItemsBlock";
import { useUserContext } from "../contexts/UserProvider";

const CampaignBox = ({data}) => {
  
  return (
    <div
      className="horizontal-box"
      style={{
        flexWrap: "wrap",
        justifyContent:
          data === undefined ||
            Object.keys(data)?.length === 0 ||
            data.length === 0
            ? "center"
            : "flex-start",
        alignContent:
          data === undefined ||
            Object.keys(data)?.length === 0 ||
            data.length === 0
            ? "center"
            : "flex-start",
      }}
    >
      {Object.keys(data)?.length === 0 || data.length === 0 ? (
        <NoItemsBlock />
      ) : (
        data.map((ele, index) => (
          <GradientCover
            key={index}
            data={{
              anilist_id: ele.anilist_id,
              name_english: ele.name_english?.hasOwnProperty("english") ? ele.name_english.english : ele.name_english,
              name_romaji: ele.name_romaji?.hasOwnProperty("romaji") ? ele.name_romaji.romaji : ele.name_romaji,
              image_url: ele.image_url?.hasOwnProperty("extraLarge") ? ele.image_url.extraLarge : ele.image_url,
              likes: ele.likes,
              funds: ele.funds
            }}
            margin={"5px"}
          />
        ))
      )}
    </div>
  );
};

export default CampaignBox;
