import React from "react";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/joy/Menu";
import Avatar from "@mui/joy/Avatar";
import Tooltip from "@mui/joy/Tooltip";
import MenuItem from "@mui/joy/MenuItem";
import MenuButton from "@mui/joy/MenuButton";
import Dropdown from "@mui/joy/Dropdown";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/joy/Divider";
import { useUserContext } from "../contexts/UserProvider";

export default function UserDropdown() {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const { user, setUser } = useUserContext();
  const logOut = () => {
    setUser({ uuid: null, email: null, liked: null });
    sessionStorage.removeItem("recs");
    signOut();
  }

  return (
    <Dropdown>
      <MenuButton variant="plain" sx={{ width: 0, height: 0 }}>
        <Tooltip /*title="Open settings"*/ variant="solid">
          <Avatar
            alt={user.email}
            // src="/static/images/avatar/2.jpg"
            sx={{
              borderRadius: "10px",
              boxShadow: "2px 2px 5px black",
            }}
          />
        </Tooltip>
      </MenuButton>
      <Menu placement="bottom-end">
        <MenuItem key={"Account"} onClick={() => navigate("/account-page")}>
          <Typography textAlign="center">Account</Typography>
        </MenuItem>
        <Divider />
        <MenuItem key={"Sign out"} onClick={() => { logOut(); window.location.href = '/' }}>
          <Typography textAlign="center">Sign out</Typography>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
