import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import Footer from "../blocks/Footer";
import { styled } from "@mui/joy";
import { useTheme } from "@mui/joy/styles";
import PriceBox from "../blocks/PriceBox";
import AlertBlock from "../blocks/AlertBlock";
import { useLocation } from "react-router-dom";
import { useAlertContext } from "../contexts/AlertProvider";

export default function BuyPage() {
  const theme = useTheme();
  const location = useLocation();
  const { setAlertState } = useAlertContext();
  const Div = styled("div")(({ theme }) => ({
    minHeight: "100vh",
    backgroundColor: theme.vars.palette.primary["200"],
  }));

  React.useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setAlertState({
        html: "Order placed! You will receive an email confirmation.",
        visibility: "visible",
      });
    }

    if (query.get("canceled")) {
      setAlertState({
        html: "Order canceled -- continue to shop around and checkout when you're ready.",
        visibility: "visible",
      });
    }
  }, []);
  return React.useMemo(() => {
    return (
      <Div className="vertical-box">
        <ResponsiveAppBar />
        <h1
          style={{ paddingLeft: "20px", color: theme.vars.palette.primary["50"] }}
        >
          Shop
        </h1>
        <PriceBox />
        <Footer />
        <AlertBlock />
      </Div>
    );
  }, [theme]);
}
