import React from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/joy/styles";
import VerifyEmailBlock from "../blocks/VerifyEmailBlock";

export default function VerifyEmailPage() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <div
      className="vertical-box"
      style={{
        minHeight: "100vh",
        backgroundColor: theme.vars.palette.primary["200"],
      }}
    >
      <VerifyEmailBlock />
    </div>
  );
}
