import React, { useState } from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import Footer from "../blocks/Footer";
import { useTheme } from "@mui/joy/styles";
import HowItWorksBlock from "../blocks/HowItWorksBlock";

export default function HowItWorksPage() {
  const theme = useTheme();

  return (
    <div style={{}}>
      <div
        style={{
          position: "absolute",
          width: "30%",
          height: "200px",
          right: 0,
          top: 0,
          backgroundImage: `url("/images/film_horizontal.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}
      ></div>
      <ResponsiveAppBar />
      <div
        className="vertical-box"
        style={{
          minHeight: "100vh",
          justifyContent: "flex-start",
          alignItems: "center",
          color: theme.vars.palette.primary["50"],
        }}
      >
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          How It Works
        </h1>
        <iframe
          src="https://player.vimeo.com/video/902188028?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="1"
          allow="autoplay; fullscreen; picture-in-picture"
          style={{
            width: "90%",
            aspectRatio: 10 / 5,
            borderRadius: "10px",
            backgroundColor: "black",
          }}
        ></iframe>
        <h2
          style={{
            alignSelf: "flex-start",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          Read more about our funding/creation process{" "}
          <a href="/blog-page">here</a>
        </h2>
        <HowItWorksBlock />
      </div>
      <Footer />
    </div>
  );
}
