import React from "react";
import { useNavigate, useHref } from "react-router-dom";
import { useSignIn, useIsAuthenticated, useSignOut } from "react-auth-kit";
import { ROOT_URL } from "../../constants/productionConstants";
import { useUserContext } from "../contexts/UserProvider";

export default function VerifyEmailBlock() {
  const [verified, setVerified] = React.useState(false);
  const href = useHref();
  const signedIn = useIsAuthenticated();
  const signOut = useSignOut();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const {setUser} = useUserContext();

  React.useEffect(() => {
    const token = href.split("/signup-verify/")[1];
    const body = JSON.stringify({
      token: token,
    });
    fetch(`${ROOT_URL}/auth/verify-email`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: body,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message === "Account activated") {
          setVerified(true);
          signOut();
          signIn({
            token: token,
            tokenType: "Bearer",
            expiresIn: 3600,
            authState: data.email,
          });
          setUser({email: data.email});
          window.setTimeout(function () {
            navigate("/");
          }, 2000);
        } else if (data.message === "Already active") {
          if (signedIn()) {
            navigate("/");
          } else {
            navigate("/signin-page");
          }
        }
      }).catch(error => console.error(error));
  }, []);

  return (
    <div>
      {verified ? (
        <p>Your account is active. Redirecting to homepage...</p>
      ) : (
        <p>verifying...</p>
      )}
    </div>
  );
}
