import React from "react";
import { getCookie } from "react-use-cookie";
import { useSignOut } from "react-auth-kit";
import { ROOT_URL } from "../../constants/productionConstants";
import shuffleArray from "../../utils/shuffleArray";
import getRecommendations from "../../utils/getRecommendations";

const UserContext = React.createContext();

export default function UserProvider({ children }) {
  const signOut = useSignOut();
  const NUM_CARDS = 10;
  const [user, setUser] = React.useState({
    uuid: null,
    email: getCookie("_auth_state")?.replaceAll('"', "") ?? null,
    liked: null,
  });
  const [fetchable, setFetchable] = React.useState(true);

  React.useEffect(() => {
    fetchData();
  }, [user.email]);

  React.useEffect(() => {
    if (fetchable && user.liked) {
      const recs = JSON.parse(sessionStorage.getItem("recs"));
      if (!recs || recs.length === 0) {
        fetchRecommendations().then(data => {
          sessionStorage.setItem("recs", JSON.stringify(data));
          setFetchable(false);
        });
      }
    }
  }, [user.liked]);

  async function fetchRecommendations() {
    const manga = [];
    if (user.liked?.length > 0) {
      const shuffledLikes = shuffleArray(user.liked);
      for (let i = 0; i < shuffledLikes.length; i++) {
        await getRecommendations(shuffledLikes[i].anilist_id).then((data) => {
          data.forEach(async (e) => {
            if (!manga.some(e2 => e.anilist_id === e2.anilist_id)) {
              manga.push({
                anilist_id: e.anilist_id,
                name_english: e.name_english,
                name_romaji: e.name_romaji,
                image_url: e.image_url
              })
            }
          })
        });
        if (manga.length >= NUM_CARDS) {
          break;
        }
      }
      const promise = new Promise((resolve, reject) => {
        manga.forEach(async (ele, i) => {
          await fetch(`${ROOT_URL}/anilist/getLikes`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              id: ele.anilist_id,
            }),
          }).then((response) => response.json())
            .then(returnData => {
              manga[i] = { ...manga[i], likes: returnData.data[0]?.likes ?? 0 }
            })
          if (i === manga.length - 1) {
            resolve(manga);
          }
        })
      })
      return promise;
    } else {
      return manga;
    }
  }

  const fetchData = async () => {
    if (user.email !== "") {
      const url = `${ROOT_URL}/auth/user-info`;
      await fetch(url, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: JSON.stringify({
          email: user.email,
        }),
      }).then((response) => response.json())
        .then((data) => {
          if (data.message === "success") {
            const userData = data.data;
            setUser({ email: user.email, uuid: userData.uuid, liked: userData.liked });
          } else {
            setUser({ email: null, uuid: null, liked: null });
            signOut();
            sessionStorage.removeItem("recs");
          }
        })
        .catch((error) => {
          console.error(error)
          setUser({ email: null, uuid: null, liked: null });
          signOut();
          sessionStorage.removeItem("recs");
        });
    }
  }
  return (
    <>
      {
        (user.email !== "" && user.liked !== null) || user.email === "" || user.email == null ? (<UserContext.Provider value={{ user, setUser }}>
          {children}
        </UserContext.Provider>) : null
      }
    </>
  );
}

export const useUserContext = () => {
  return React.useContext(UserContext);
};
