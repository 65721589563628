import React from "react";
import { useTheme } from "@mui/joy/styles";

export default function HowItWorksBlock() {
  const theme = useTheme();
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

  return (
    <div
      className="horizontal-box"
      style={{
        flexDirection: screenWidth > 800 ? "row" : "column",
        margin: "0 20px",
        marginTop: "60px",
        color: theme.vars.palette.primary["50"],
        width: "90%",
      }}
    >
      <div
        className="vertical-box"
        style={{
          flex: 1,
          alignItems: "center",
          padding: "0px 20px",
        }}
      >
        <img src="/images/book.svg" style={{ height: "200px" }} />
        <h1
          style={{
            textAlign: "center",
            padding: "20px 0",
            fontSize: "25px",
          }}
        >
          Find Your Favorite Manga/Webtoons
        </h1>
      </div>
      <div
        className="vertical-box"
        style={{
          flex: 1,
          alignItems: "center",
          padding: "0px 20px",
        }}
      >
        <img src="/images/love.svg" style={{ height: "200px" }} />
        <h1
          style={{
            textAlign: "center",
            padding: "20px 0",
            fontSize: "25px",
          }}
        >
          Support The Project For Production
        </h1>
      </div>
      <div
        className="vertical-box"
        style={{
          flex: 1,
          alignItems: "center",
          padding: "0px 20px",
        }}
      >
        <img src="/images/movie.svg" style={{ height: "200px" }} />
        <h1
          style={{
            textAlign: "center",
            padding: "20px 0",
            fontSize: "25px",
          }}
        >
          Watch & Enjoy The Funded Project!
        </h1>
      </div>
    </div>
  );
}
