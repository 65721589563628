import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import Footer from "../blocks/Footer";
import { useTheme } from "@mui/joy/styles";
import DesktopCampaignBlock from "../blocks/DesktopCampaignBlock";
import PhoneCampaignBlock from "../blocks/PhoneCampaignBlock";
import AlertBlock from "../blocks/AlertBlock";
import CampaignCarouselBlock from "../blocks/CampaignCarouselBlock";
import { useParams } from "react-router-dom";
import { URL, CAMPAIGN_QUERY, RECOMMENDATION_QUERY } from "../../constants/gqlQueryConstants";
import ErrorPage from "./ErrorPage";
import SearchBarAtom from "../atoms/SearchBarAtom";

export default function CampaignPage() {
  const theme = useTheme();
  const params = useParams();
  const NUM_RECS = 10;
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [params])
  window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

  async function getRecommendations() {
    let pageNum = 1;
    let hasNextPage = true;
    const recs = [];

    try {
      while (recs.length < NUM_RECS && hasNextPage) {
        const variables_rec = {
          page: pageNum,
          perPage: 10,
          search: params.id,
        };
        const options_rec = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({
            query: RECOMMENDATION_QUERY,
            variables: variables_rec
          })
        };
        await fetch(URL, options_rec)
          .then((response) => response.json())
          .then((returnData) => {
            hasNextPage = returnData.data.Page.pageInfo.hasNextPage;
            returnData.data.Page.recommendations.forEach((ele) => {
              const adaptation = ele.mediaRecommendation.relations.edges.find((e) =>
                (e?.relationType === "ADAPTATION" ||
                  e?.relationType === "ALTERNATIVE") &&
                e?.node?.type === "ANIME"
              );
              if (adaptation === undefined && recs.length < NUM_RECS && !recs.some(e => e.anilistId === ele.mediaRecommendation.id)) {
                recs.push({
                  anilist_id: ele.mediaRecommendation.id,
                  name_english: ele.mediaRecommendation.title.english,
                  name_romaji: ele.mediaRecommendation.title.romaji,
                  image_url: ele.mediaRecommendation.coverImage.extraLarge,
                });
              }
            });
            pageNum++;
          })
      }
      return recs;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }

  React.useEffect(() => {
    const variables_campaign = {
      id: params.id
    };
    const options_campaign = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        query: CAMPAIGN_QUERY,
        variables: variables_campaign
      })
    };

    fetch(URL, options_campaign).then(response => response.json())
      .then((returnData) => {
        if (returnData.data.Media === null || returnData.data.errors) {
          setError(true)
        } else {
          setData(returnData.data.Media);
        }
      })
      .catch(error => console.error(error));
  }, []);

  return (
    <>
      {error ? <ErrorPage /> :
        <div
          className="vertical-box"
          style={{ backgroundColor: theme.vars.palette.primary["200"] }}
        >
          <ResponsiveAppBar />
          <h1
            style={{
              textAlign: "center",
              color: theme.vars.palette.primary["50"],
              marginTop: "20px",
              fontSize: "3vw",
            }}
          >
            Your Favorite Manga or Web Series
          </h1>
          <SearchBarAtom />
          {screenWidth > 800 ? <DesktopCampaignBlock data={data} /> : <PhoneCampaignBlock data={data} />}
          <div className="vertical-box" style={{ marginTop: "20px" }}>
            <h1
              style={{
                paddingLeft: "20px",
                color: theme.vars.palette.primary["50"],
              }}
            >
              Similar
            </h1>
            <CampaignCarouselBlock promise={getRecommendations()} />
          </div>
          <Footer />
          <AlertBlock visibility="hidden" />
        </div>
      }
    </>
  );
}
