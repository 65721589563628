import * as React from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Input from "@mui/joy/Input";
import { Button } from "@mui/joy";
import Snackbar from "@mui/joy/Snackbar";
import copy from "copy-to-clipboard";

export default function ShareModal(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={props.open}
      onClose={(event) => {
        event.stopPropagation();
        props.setOpen(false);
        setOpen(false);
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 500,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <h2>Share</h2>
        <p>Share this campaign with your friends</p>
        <Input
          placeholder="URL"
          value={props.value}
          variant="outlined"
          sx={{ margin: "20px 0px" }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          endDecorator={
            <Button
              onClick={async () => {
                copy(props.value);
                setOpen(true);
              }}
            >
              Copy
            </Button>
          }
          readOnly
        />
        <Snackbar
          autoHideDuration={3000}
          open={open}
          variant="outlined"
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpen(false);
          }}
        >
          Copied to Clipboard!
        </Snackbar>
      </Sheet>
    </Modal>
  );
}
