import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import { useTheme } from "@mui/joy/styles";
import NewPasswordBlock from "../blocks/NewPasswordBlock";
import AlertBlock from "../blocks/AlertBlock";

export default function NewPasswordPage() {
  const theme = useTheme();

  return (
    <div
      className="vertical-box"
      style={{
        minHeight: "100vh",
        backgroundColor: theme.vars.palette.primary["200"],
      }}
    >
      <ResponsiveAppBar />
      <NewPasswordBlock />
      <AlertBlock visibility="hidden" />
    </div>
  );
}
