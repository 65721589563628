import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import { useTheme } from "@mui/joy/styles";
import AlertBlock from "../blocks/AlertBlock";

export default function ErrorPage() {
  const theme = useTheme();

  return (
    <div
      className="vertical-box"
      style={{
        minHeight: "100vh",
        backgroundColor: theme.vars.palette.primary["200"],
      }}
    >
      <ResponsiveAppBar />
      <div
        className="vertical-box"
        style={{
          justifyContent: "center",
          alignItems: "center",
          flex: "1",
          padding: "80px 0",
          backgroundColor: theme.vars.palette.primary["200"],
        }}
      >
        <img
          src="/images/404.svg"
          alt="No Results"
          style={{ width: "90%", maxWidth: "600px" }}
        />

        <h2
          style={{
            color: theme.vars.palette.primary["50"],
            fontSize: "40px",
            padding: "0 20px",
            textAlign: "center",
          }}
        >
          We couldn't find what you were looking for
        </h2>
        <h2
          style={{
            color: theme.vars.palette.primary["50"],
            fontSize: "40px",
            padding: "0 20px",
            textAlign: "center",
          }}
        >
          Go back to <a href="/">Homepage</a>
        </h2>
      </div>
      <AlertBlock visibility="hidden" />
    </div>
  );
}
