import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/joy/styles";

export default function NoTransactionsBlock() {
  const theme = useTheme();

  return (
    <div>
      <div className="horizontal-box" style={{ justifyContent: "center" }}>
        <CloseIcon
          style={{
            width: "100px",
            height: "100px",
            backgroundColor: theme.vars.palette.primary["50"],
            color: theme.vars.palette.primary["100"],
            borderRadius: "20px",
            marginBottom: "10px",
          }}
        />
      </div>
      <h1
        style={{
          color: theme.vars.palette.primary["50"],
          textAlign: "center",
        }}
      >
        No Transactions
      </h1>
    </div>
  );
}
