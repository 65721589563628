import React from "react";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/joy/Menu";
import Tooltip from "@mui/joy/Tooltip";
import MenuItem from "@mui/joy/MenuItem";
import MenuButton from "@mui/joy/MenuButton";
import MenuIcon from "@mui/icons-material/Menu";
import Dropdown from "@mui/joy/Dropdown";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/joy/styles";
import Divider from "@mui/joy/Divider";

export default function MenuDropdown() {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <Dropdown>
      <MenuButton
        variant="plain"
        sx={{ width: 0, height: 0, marginRight: "10px", marginTop: "10px" }}
      >
        <Tooltip /*title="Open menu"*/ variant="solid">
          <MenuIcon style={{ color: theme.vars.palette.primary["50"] }} />
        </Tooltip>
      </MenuButton>
      <Menu placement="bottom-end">
        <MenuItem
          key={"How It Works"}
          onClick={() => (window.location.href = "/how-it-works-page")}
        >
          <Typography textAlign="center">How It Works</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          key={"Liked"}
          onClick={() => (window.location.href = "/liked-page")}
        >
          <Typography textAlign="center">Liked</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          key={"Blog"}
          onClick={() => (window.location.href = "/blog-directory-page")}
        >
          <Typography textAlign="center">Blog</Typography>
        </MenuItem>
      </Menu>
    </Dropdown>
  );
}
