import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/joy/styles";

export default function BlogPreview() {
  const theme = useTheme();

  const [maxWords, setMaxWords] = useState(500);

  useEffect(() => {
    const handleResize = () => {
      setMaxWords(Math.min(window.innerWidth / 3.5, 500));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const content = `Have you ever opened up a streaming platform and browsed around
   hundreds of selections only to find none that you actually want to
   watch? Ever disappointed that your favorite TV show on the streaming
   platform has been canceled for the next season? I certainly know I
   have and guessing by the fact that you’re on this site, you’ve
   probably had these issues too 😔. It seems like none of these
   streaming platforms really know (or care) what their customers
   actually want.

   Nowadays, the most common source for watching a movie or TV show is
   through a streaming service. Unfortunately, with so many companies now
   vying for the same customers, what was once a great solution to
   getting a wide selection conveniently within the comfort of your home,
   has devolved into a battle royale between streaming companies on who
   can provide the most content. While occasionally these companies do
   get things right and produce decent content, their focus on quantity
   over quality has led to the vast majority of streaming content being,
   well – lackluster.
  `;

  return (
    <div
      className="horizontal-box blog-box"
      style={{
        height: "20vw",
        minHeight: "200px",
        width: "95%",
        backgroundColor: theme.vars.palette.primary["150"],
        borderRadius: "20px",
        alignItems: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        cursor: "pointer",
        margin: "20px 0",
        border: "1px solid white",
      }}
      onClick={() => (window.location.href = "/blog-page")}
    >
      <div
        style={{
          height: "40%",
          aspectRatio: "1/1",
          backgroundColor: "black",
          background: `url("/images/logo.png")`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          marginLeft: "20px",
          borderRadius: "20px",
        }}
      ></div>
      <div
        className="vertical-box"
        style={{
          margin: "0 20px",
          flex: 1,
          height: "90%",
          overflow: "scroll",
        }}
      >
        <h1>Wish It. Flix It.</h1>
        <p style={{ position: "inline" }}>
          {content.substring(0, maxWords)}...&nbsp;
          <a
            href="/blog-page"
            style={{ color: theme.vars.palette.primary["100"] }}
          >
            Read More
          </a>
        </p>
      </div>
    </div>
  );
}
