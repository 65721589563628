import React from "react";
import Button from "@mui/joy/Button";
import { useTheme } from "@mui/joy/styles";
import { useIsAuthenticated } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../contexts/UserProvider";

export default function PriceCard(props) {
  

  const theme = useTheme();
  const signedIn = useIsAuthenticated();
  const navigate = useNavigate();
  const { user } = useUserContext();
  function openStripePurchase() {
    if (signedIn()) {
      const body = JSON.stringify({ email: user.email, stripeProductId: props.stripeProductId});
      fetch(`/stripe/create-checkout-session`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: body,
      })
      .then((response) => response.json()).then((data) => window.location.href = data.url).catch(error => console.error(error));
    } else {
      navigate('/signin-page');
    }
  }
  return (
    <div
      className="vertical-box responsive-box"
      style={{
        flexBasis: "calc(20% - 40px)",
        margin: props.margin,
        boxSizing: "border-box",
        aspectRatio: "5/6",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.vars.palette.primary["100"],
        borderRadius: "20px",
        boxShadow: "2px 2px 5px black",
        color: theme.vars.palette.primary["50"],
        background: `url("/images/stars.gif")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
    >
      <div style={{ position: "relative"}}>
        <img src="/images/shine.gif" alt="" style={{width: 
        "100%"}} />
        <div style={{width: "70%", height: "70%",
        position: "absolute", bottom: "17%", left: "18.5%"
        , background: `url("/images/star_coin.png")`
        , backgroundSize: "contain", backgroundRepeat: "no-repeat"}}>
        </div>
      </div>
      <h1 style={{ textAlign: "center", fontFamily: "Ubuntu Mono"}}>{props.title}</h1>
      <Button
        style={{
          marginTop: "20px",
          boxShadow: "2px 2px 5px black",
          fontSize: "1.25rem",
          fontFamily: "Ubuntu Mono",
          height: "40px",
          backgroundColor: theme.vars.palette.primary["150"],
          borderRadius: "10px",
          marginBottom: "20px",
        }}
        onClick={openStripePurchase}
      >
        Buy: ${props.price}
      </Button>
    </div>
  );
}