import React from "react";
import { useTheme } from "@mui/joy/styles";
import PriceCard from "./PriceCard";
import { loadStripe } from "@stripe/stripe-js";

export default function PriceBox() {
  

  const [productData, setProductData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    fetch(`/stripe/get-test-products`)
      .then((response) => response.json())
      .then((data) =>
        setProductData(
          data?.data?.sort((a, b) =>
            a.price_in_usd > b.price_in_usd
              ? 1
              : b.price_in_usd > a.price_in_usd
              ? -1
              : 0
          )
        )
      ).catch(error => console.error(error));
  }, []);
  return (
    <div
      className="horizontal-box"
      style={{
        flexWrap: "wrap",
        flex: 1,
      }}
    >
      {productData?.map((ele) => (
        <PriceCard
          margin="20px"
          title={ele.name}
          price={ele.price_in_usd}
          stripeProductId={ele.stripe_product_id}
        />
      ))}
    </div>
  );
}
