import React from "react";
import PillboxButton from "../atoms/PillboxButton";
import IconButton from '@mui/material/IconButton';
import Input from "@mui/joy/Input";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTheme } from "@mui/joy/styles";
import { useGoogleLogin } from "@react-oauth/google";
import { useSignIn } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useAlertContext } from "../contexts/AlertProvider";
import { useUserContext } from "../contexts/UserProvider";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../constants/regexConstants";
import { ROOT_URL } from "../../constants/productionConstants";

export default function SignUpBlock() {
  const theme = useTheme();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const { setAlertState } = useAlertContext();
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      fetch(`${ROOT_URL}/auth/check-google-token`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: JSON.stringify({ code: tokenResponse.code }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.message === "success") {
            signIn({
              token: data.token,
              expiresIn: 3600,
              authState: data.email,
              tokenType: "Bearer",
            });
            navigate("/");
          }
        }).catch(error => console.error(error));
    },
    flow: "auth-code",
  });
  const { user } = useUserContext();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordVerify, setPasswordVerify] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = React.useState(false);

  const [validEmail, setValidEmail] = React.useState(false);
  const [validPassword, setValidPassword] = React.useState(false);
  const [verifyPassword, setVerifyPassword] = React.useState(false);

  function checkEmail(e) {
    setEmail(e);
    if (e !== "") {
      if (!EMAIL_REGEX.test(e)) {
        setValidEmail(false);
      } else {
        const body = JSON.stringify({ email: e });
        fetch(`${ROOT_URL}/auth/check-email`, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          mode: "cors",
          body: body,
        })
          .then((response) => response.json())
          .then((data) => {
            data.message === true ? setValidEmail(false) : setValidEmail(true);
          }).catch(error => console.error(error));
      }
    }
  }

  function checkPassword(p) {
    setPassword(p);
    if (p !== "") {
      setValidPassword(PASSWORD_REGEX.test(p));
    }
  }

  function checkVerifyPassword(v) {
    setPasswordVerify(v);
    setVerifyPassword(v === password);
  }

  function checkEnterKey(event) {
    if (event.key === "Enter") {
      submitForm();
    }
  }

  React.useEffect(() => {
    if (user.email) {
      navigate("/");
    }
  }, []);

  function submitForm() {
    if (!validEmail || !validPassword || !verifyPassword) {
      setAlertState({
        html: "Please enter a valid email and password",
        visibility: "visible",
      });
    } else {
      const body = JSON.stringify({ email: email, password: password });
      const route = `${ROOT_URL}/auth/signup`;
      fetch(route, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: body,
      })
        .then((response) => response.json())
        .then((data) =>
          data.message === "success"
            ? navigate("/check-email-page", {
              state: { email: email, route: "/auth/signup" },
            })
            : ""
        ).catch(error => console.error(error));
    }
  }
  return (
    <div
      className="vertical-box"
      style={{
        justifyContent: "center",
        alignItems: "center",
        top: "5%",
        position: "relative",
        flex: 1,
        padding: "40px 0",
      }}
    >
      <h1 style={{ color: theme.vars.palette.primary["50"], margin: "20px 0" }}>
        Sign Up
      </h1>
      <div style={{ width: "80%", maxWidth: "400px" }}>
        <Input
          placeholder="Email"
          variant="soft"
          required
          data-skip-inverted-colors
          sx={{
            height: "50px",
            marginTop: "20px",
            borderRadius: "50px",
            boxShadow: "2px 2px 5px black",
            fontFamily: "Oswald, cursive",
            "--Input-focusedHighlight": theme.vars.palette.primary["50"],
          }}
          onChange={(event) => checkEmail(event.target.value)}
          onKeyDown={checkEnterKey}
        />
        <div
          hidden={validEmail || email.length === 0}
          style={{
            color: "red", fontSize: "12px", padding: "5px 10px 0px 10px"
            , marginBottom: "-10px"
          }}
        >
          Email is invalid or is already in use
        </div>
        <Input
          placeholder="Password"
          variant="soft"
          type={showPassword ? "" : "password"} 
          required
          data-skip-inverted-colors
          sx={{
            height: "50px",
            marginTop: "20px",
            borderRadius: "50px",
            boxShadow: "2px 2px 5px black",
            fontFamily: "Oswald, cursive",
            "--Input-focusedHighlight": theme.vars.palette.primary["50"],
          }}
          onChange={(event) => checkPassword(event.target.value)}
          endDecorator={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <div
          hidden={validPassword || password.length === 0}
          style={{
            color: "red", fontSize: "12px", padding: "5px 10px 0px 10px",
            marginBottom: "-10px"
          }}
        >
          Password must have at least eight characters, one letter, one number
          and one special character
        </div>
        <Input
          placeholder="Retype Password"
          variant="soft"
          type={showVerifyPassword ? "" : "password"} 
          required
          data-skip-inverted-colors
          sx={{
            height: "50px",
            marginTop: "20px",
            borderRadius: "50px",
            boxShadow: "2px 2px 5px black",
            fontFamily: "Oswald, cursive",
            "--Input-focusedHighlight": theme.vars.palette.primary["50"],
          }}
          onChange={(event) => checkVerifyPassword(event.target.value)}
          onKeyDown={checkEnterKey}
          endDecorator={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                edge="end"
              >
                {showVerifyPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        />
        <div
          hidden={verifyPassword || passwordVerify.length === 0}
          style={{
            color: "red", fontSize: "12px", padding: "5px 10px 0px 10px"
            , marginBottom: "-10px"
          }}
        >
          Must match
        </div>
        <div style={{ marginTop: "20px" }}>
          <PillboxButton onClick={submitForm}>
            <h3>Sign Up</h3>
          </PillboxButton>
        </div>
        <p
          style={{
            marginBottom: "20px",
            color: theme.vars.palette.primary["50"],
            textAlign: "center",
          }}
        >
          Already have an account? <a href="/signin-page">Sign In</a>
        </p>
        <div
          className="horizontal-box"
          style={{
            height: "20px",
            justifyContent: "space-around",
            marginBottom: "20px",
            alignItems: "center",
          }}
        >
          <hr
            style={{
              width: "100%",
              margin: "10px",
              border: "0",
              height: "1px",
              background: "grey",
            }}
          />
          <h2 style={{ color: theme.vars.palette.primary["50"] }}>OR</h2>
          <hr
            style={{
              width: "100%",
              margin: "10px",
              border: "0",
              height: "1px",
              background: "grey",
            }}
          />
        </div>
        <PillboxButton onClick={() => googleLogin()}>
          <div
            className="horizontal-box"
            style={{
              justifyContent: "space-around",
              alignItems: "center",
              height: "100%",
              color: theme.vars.palette.primary["50"],
            }}
          >
            <img
              src="images/google.png"
              alt=""
              style={{
                width: "min(calc(8px + 3vw), 25px)",
                height: "min(calc(8px + 3vw), 25px)",
                marginRight: "min(calc(4px + 3vw), 20px)",
              }}
            />
            <h3>Sign Up With Google</h3>
            <div
              style={{
                width: "min(calc(8px + 3vw), 25px)",
                height: "min(calc(8px + 3vw), 25px)",
                marginLeft: "min(calc(4px + 3vw), 20px)",
              }}
            ></div>
          </div>
        </PillboxButton>
      </div>
    </div>
  );
}
