import React from "react";
import { useTheme } from "@mui/joy/styles";
import TransactionDetailsBox from "./TransactionDetailsBox";
import parse from "html-react-parser";

export default function CampaignDetailsBlock(props) {
  const theme = useTheme();
  return (
    <div
      className="vertical-box"
      style={{
        alignItems: "center",
        overflow: "auto",
        width: "100%",
      }}
    >
      <div style={{ display: props.isDetailsVisible ? "block" : "none" }}>
        <h1
          style={{
            marginTop: "20px",
            alignSelf: "flex-start",
            color: theme.vars.palette.primary["50"],
          }}
        >
          Details
        </h1>
        <p
          style={{
            maxHeight: "150px",
            overflow: "auto",
            width: "100%",
            marginTop: "20px",
          }}
        >
          {props.description ? parse(props.description) : ""}
        </p>
      </div>

      <h1
        style={{
          marginTop: "20px",
          alignSelf: "flex-start",
          color: theme.vars.palette.primary["50"],
        }}
      >
        Prizes
      </h1>
      <div
        style={{
          maxHeight: "300px",
          overflow: "auto",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <TransactionDetailsBox numTransactions={5} />
      </div>
      <h1
        style={{
          marginTop: "20px",
          alignSelf: "flex-start",
          color: theme.vars.palette.primary["50"],
        }}
      >
        Updates
      </h1>
      <div
        style={{
          maxHeight: "300px",
          overflow: "auto",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <TransactionDetailsBox numTransactions={5} />
      </div>
      <h1
        style={{
          marginTop: "20px",
          alignSelf: "flex-start",
          color: theme.vars.palette.primary["50"],
        }}
      >
        Comments
      </h1>
    </div>
  );
}
