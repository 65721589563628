import * as React from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Input from "@mui/joy/Input";
import { Button } from "@mui/joy";
import Snackbar from "@mui/joy/Snackbar";
import copy from "copy-to-clipboard";
import { useUserContext } from "../contexts/UserProvider";
import { EMAIL_REGEX } from "../../constants/regexConstants";
import { useNavigate } from "react-router-dom";
import { ROOT_URL } from "../../constants/productionConstants";
export default function EditEmailModal(props) {
  const { user, setUser } = useUserContext();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [newEmail, setNewEmail] = React.useState('');
  const [snackbarMessage, setSnackbarMessage] = React.useState("");

  function submitEmailChange() {
    if (user.email === newEmail) {
      setSnackbarMessage("New email can't be the same as current email");
      setOpen(true);
    } else if (!EMAIL_REGEX.test(newEmail)) {
      setSnackbarMessage("Invalid email format");
      setOpen(true);
    } else {
      const route = `${ROOT_URL}/auth/set-new-email`;
      fetch(route, {
        method: 'post',
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: JSON.stringify({ currentEmail: user.email, newEmail: newEmail })
      }).then(response => response.json())
        .then(data => data.message === "success"
          ? navigate("/check-email-page", {
            state: { email: newEmail, route: "/auth/set-new-email" },
          }) && setUser({ ...user, email: newEmail })
          : "");
    }
  }

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={props.open}
      onClose={(event) => {
        event.stopPropagation();
        props.setOpen(false);
        setOpen(false);
        setNewEmail("");
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "40px",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          maxWidth: 500,
          borderRadius: "md",
          p: 3,
          boxShadow: "lg",
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <h2>Change Email</h2>
        <p>Enter your new email</p>
        <Input
          placeholder="New Email"
          variant="outlined"
          sx={{ margin: "20px 0px" }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          onChange={(event) => setNewEmail(event.target.value)}
          endDecorator={
            <Button
              onClick={async () => {
                copy(props.value);
                submitEmailChange();
              }}
            >
              Save
            </Button>
          }
        />
        <Snackbar
          autoHideDuration={3000}
          open={open}
          variant="outlined"
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpen(false);
          }}
        >
          {snackbarMessage}
        </Snackbar>
      </Sheet>
    </Modal>
  );
}
