import React from "react";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useTheme } from "@mui/joy/styles";

export default function NoItemsBlock() {
  const theme = useTheme();

  return (
    <div
      className="vertical-box"
      style={{
        color: theme.vars.palette.primary["50"],
        justifyContent: "center",
        alignItems: "center",
        marginTop: "50px",
        height: "100%",
      }}
    >
      <img
        src="/images/void.svg"
        alt="No Results"
        style={{
          width: "70%",
          minWidth: "200px",
          maxWidth: "700px",
          marginBottom: "40px",
        }}
      />
      <h1>Nothing Here</h1>
    </div>
  );
}
