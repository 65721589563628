import React from "react";
import { useTheme } from "@mui/joy/styles";

export default function CoinPurchaseDetails() {
  const theme = useTheme();

  return (
    <div>
      <hr
        style={{
          height: "1px",
          border: "0",
          background: "grey",
          margin: '0 20%'
        }}
      />
      <div
        className="horizontal-box"
        style={{
          justifyContent: "space-between",
          color: theme.vars.palette.primary["50"],
          overflow: "auto",
        }}
      >
        <div
          className="horizontal-box"
          style={{
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "10px 0",
            width: "100%",
          }}
        >
          <img src="/images/star_coin.png" style={{marginLeft: '10px', width: '60px'}}/>
          <div
            className="vertical-box"
            style={{
              margin: "0 20px",
              alignSelf: "flex-start",
              width: "100%",
            }}
          >
            <div
              className="horizontal-box"
              style={{
                justifyContent: "space-between",
              }}
            >
              <h1 style={{ paddingRight: "10px" }}>Lorem Ipsum</h1>
              <h2>02/14/2003</h2>
            </div>
            <h2>Bought 50 coins</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
