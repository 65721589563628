import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import SignInBlock from "../blocks/SignInBlock";
import { useTheme } from "@mui/joy/styles";
import AlertBlock from "../blocks/AlertBlock";
import { useAlertContext } from "../contexts/AlertProvider";

export default function SignInPage() {
  const theme = useTheme();
  const { alertState } = useAlertContext();
  return (
    <div
      className="vertical-box"
      style={{
        minHeight: "100vh",
        backgroundColor: theme.vars.palette.primary["200"],
      }}
    >
      <ResponsiveAppBar />
      <SignInBlock/>
      <AlertBlock visibility={alertState.visibility} />
    </div>
  );
}
