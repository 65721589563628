import * as React from "react";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";
import { useCountUp, CountUp } from "use-count-up";

export default function LinearProgressCountUp(props) {
  const { value } = useCountUp({
    isCounting: true,
    duration: 2,
    easing: "easeOutCubic",
    start: 0,
    end: (Math.min(props.likes ?? 0, 50000) / 50000) * 100,
  });

  const { likesValue } = useCountUp({
    isCounting: true,
    duration: 2,
    easing: "easeOutCubic",
    start: 0,
    end: 75,
  });

  return (
    <div>
      {" "}
      <Typography
        level="body-xs"
        fontWeight="xl"
        textColor="#f6cf96"
        fontSize="20px"
        textAlign="end"
        sx={{ mixBlendMode: "difference" }}
      >
        <div style={{ marginRight: "10px" }}>
          <CountUp isCounting end={props.likes} duration={2} />{" "}
          {props.likes == 1 ? "LIKE" : "LIKES"}
        </div>
      </Typography>
      <LinearProgress
        determinate
        variant="outlined"
        color="neutral"
        size="sm"
        thickness={24}
        value={Number(value)}
        sx={{
          "--LinearProgress-radius": "15px",
          "--LinearProgress-thickness": "30px",
          color: "#6d67e4",
        }}
      ></LinearProgress>
    </div>
  );
}
