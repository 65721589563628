import React from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import IntroductionBlock from "../blocks/IntroductionBlock";
import Footer from "../blocks/Footer";
import { styled } from "@mui/joy";
import { useTheme } from "@mui/joy/styles";
import AlertBlock from "../blocks/AlertBlock";
import CampaignCarouselBlock from "../blocks/CampaignCarouselBlock";
import { ScrollRestoration } from "react-router-dom";
import { ROOT_URL } from "../../constants/productionConstants";
import CircularProgress from '@mui/joy/CircularProgress';

export default function HomePage() {
  const theme = useTheme();
  const Div = styled("div")(({ theme }) => ({
    backgroundColor: theme.vars.palette.primary["200"],
  }));

  const [isLoading, setIsLoading] = React.useState(true);

  async function fetchGenre(genre) {
    const url = `${ROOT_URL}/anilist/populateGenre`;
    const data = await fetch(url, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      body: JSON.stringify({
        genre: genre,
      }),
    })
      .then((response) => response.json())
      .catch((err) => console.error(err));
    return data?.data;
  }

  function checkRecommended() {
    const recs = JSON.parse(sessionStorage.getItem("recs"));
    if (recs?.length > 0) {
      return new Promise((resolve, reject) => {
        resolve(recs);
      });
    } else {
      return fetchGenre("Drama");
    }
  }

  return (
    <Div className="vertical-box">
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "black",
            background:
              "linear-gradient(to top, #03001C, rgba(0,0,0,0) 150px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 250px)",
          }}
        ></div>
        <div
          style={{
            backgroundImage: `url("/images/boomerang_vast.gif")`,
            backgroundSize: "cover",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
          }}
        >
          <ResponsiveAppBar />
          <IntroductionBlock />
        </div>
      </div>
      <h1
        style={{
          paddingLeft: "20px",
          color: theme.vars.palette.primary["50"],
          display: isLoading ? "none" : "block"
        }}
      >
        Recommended
      </h1>
      <CampaignCarouselBlock promise={checkRecommended()} setIsLoading={setIsLoading} />
      <h1
        style={{
          paddingLeft: "20px",
          color: theme.vars.palette.primary["50"],
          display: isLoading ? "none" : "block"
        }}
      >
        Trending
      </h1>
      <CampaignCarouselBlock promise={fetchGenre("Trending")} setIsLoading={setIsLoading} />
      <h1
        style={{
          paddingLeft: "20px",
          color: theme.vars.palette.primary["50"],
          display: isLoading ? "none" : "block"
        }}
      >
        Action
      </h1>
      <CampaignCarouselBlock promise={fetchGenre("Action")} setIsLoading={setIsLoading} />
      <h1
        style={{
          paddingLeft: "20px",
          color: theme.vars.palette.primary["50"],
          display: isLoading ? "none" : "block"
        }}
      >
        Romance
      </h1>
      <CampaignCarouselBlock promise={fetchGenre("Romance")} setIsLoading={setIsLoading} />
      <div style={{ display: isLoading ? "flex" : "none", alignItems: "center",padding: '100px 0'  }} className="vertical-box">
        <CircularProgress sx={{ "--CircularProgress-progressColor": theme.vars.palette.primary["100"] }} size="lg" />
      </div>
      <Footer />
      <AlertBlock visibility="hidden" />
      <ScrollRestoration />
    </Div>
  );
}
