import React, { useState } from "react";
import ResponsiveAppBar from "../blocks/ResponsiveAppBar";
import Footer from "../blocks/Footer";
import { useTheme } from "@mui/joy/styles";
import BlogPreview from "../blocks/BlogPreview";

export default function BlogDirectoryPage() {
  const theme = useTheme();

  return (
    <div style={{}}>
      <div
        style={{
          position: "absolute",
          width: "30%",
          height: "200px",
          right: 0,
          top: 0,
          backgroundImage: `url("/images/film_horizontal.png")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: -1,
        }}
      ></div>
      <ResponsiveAppBar />
      <div
        className="vertical-box"
        style={{
          minHeight: "100vh",
          justifyContent: "flex-start",
          alignItems: "center",
          color: theme.vars.palette.primary["50"],
        }}
      >
        <h1 style={{ textAlign: "center" }}>Blogs</h1>
        <BlogPreview />
      </div>
      <Footer />
    </div>
  );
}
