import React from "react";
import { useTheme } from "@mui/joy/styles";

export default function CampaignFundingDetails() {
  const theme = useTheme();

  return (
    <div>
      <hr
        style={{
          height: "1px",
          border: "0",
          background: "grey",
          margin: '0 20%'
        }}
      />
      <div
        className="horizontal-box"
        style={{
          justifyContent: "space-between",
          color: theme.vars.palette.primary["50"],
          overflow: "auto",
        }}
      >
        <div
          className="horizontal-box"
          style={{
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "10px 0",
            width: "100%",
          }}
        >
          <div style={{marginLeft: '10px', aspectRatio: 7/10, width: '60px'}}>
          <img
            src="https://static.displate.com/280x392/displate/2021-08-23/65b2850cd18f6275ea9a853d81319eeb_8be9086d4fe10e1960b5640b10376f70.jpg"
            style={{
              width: "inherit",
              borderRadius: "5px",
              boxShadow: "2px 2px 5px black",
              objectFit: 'cover'
            }}
          />
          </div>
          <div
            className="vertical-box"
            style={{
              margin: "0 20px",
              alignSelf: "flex-start",
              width: "100%",
            }}
          >
            <div
              className="horizontal-box"
              style={{ justifyContent: "space-between" }}
            >
              <h1 style={{ paddingRight: "10px" }}>Lorem Ipsum</h1>
              <h2>02/14/2003</h2>
            </div>
            <h2>Funded 50 coins</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
