import { extendTheme } from "@mui/joy/styles";
import { experimental_extendTheme as materialExtendTheme } from "@mui/material/styles";

export const joyTheme = extendTheme({
  colorSchemes: {
    // Light Theme (Red & Yellow Accents)
    // 50: "#E97777",
    // 100: "#FCDDB0",
    // 150: "#EAE0DA",
    // 200: "#F5EBE0",
    light: {
      palette: {
        primary: {
          50: "#fff5b9", // highlights (icons)
          100: "#f6cf96", // icon bg
          150: "#f9caac", // magnifying glass & sign in button
          200: "#6d2730", // bg
          footer: "",
        },
      },
    },

    // Dark Theme (Blue & Purple Accents)
    // 50: "#E5B8F4",
    // 100: "#6D67E4",
    // 150: "#352F44",
    // 200: "#03001C",

    // Light Theme (Red & Yellow Accents)
    // 50: "#E97777", 
    // 100: "#FCDDB0", 
    // 150: "#EAE0DA", 
    // 200: "#F5EBE0", 
    // check
    dark: {
      palette: {
        primary: {
          50: "#FFFFFF", // highlights (icons)
          100: "#6D67E4", // icon bg
          150: "#352F44", // magnifying glass & sign in button
          175: '#14064a',
          200: "#03001C", // bg
          footer: "",
        },
      },
    },
  },
});

export const muiDarkTheme = materialExtendTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#000000",
      50: "#000000",
      100: "#000000",
      150: "#000000",
      200: "#000000",
      900: "#000000",
      footer: "white",
    },
  },
});
