import React from "react";
import IconButton from "@mui/joy/IconButton";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useTheme } from "@mui/joy/styles";
import { useUserContext } from "../contexts/UserProvider";
import { ROOT_URL } from "../../constants/productionConstants";

export default function LikeIcon(props) {
  const theme = useTheme();
  const { user, setUser } = useUserContext();
  const [likes, setLikes] = React.useState(0);

  React.useEffect(() => {
    setLikes(props.data?.likes ?? 0);
  }, [props.data]);
  
  async function likeCampaign(e) {
    e.stopPropagation();
    e.preventDefault();
    if (user.email !== "") {
      let newLiked = [];
      if (!user.liked?.some((ele) => ele.anilist_id === props.data.anilist_id)) {
        newLiked = user.liked?.slice().concat({
          anilist_id: props.data.anilist_id,
          image_url: props.data.image_url,
          name_english: props.data.name_english,
          name_romaji: props.data.name_romaji,
          likes: likes + 1,
          funds: props.data.funds
        });
        props.setData({ ...props.data, likes: likes + 1 });
        setLikes(likes + 1);
      } else {
        newLiked = user.liked.filter((ele) => ele.anilist_id !== props.data.anilist_id);
        props.setData({ ...props.data, likes: likes - 1 });
        setLikes(likes - 1);
      }
      setUser({ ...user, liked: newLiked });

      fetch(`${ROOT_URL}/userInteraction/likeCampaignUpdate`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: JSON.stringify({
          anilistId: props.data.anilist_id,
          englishName: props.data.name_english,
          romajiName: props.data.name_romaji,
          imageUrl: props.data.image_url,
          userEmail: user.email,
        }),
      }).catch(error => console.error(error));
    } else {
      window.location.href = "/signin-page";
    }
  }
  return (
    <IconButton
      sx={{
        "--IconButton-size": "17px",
        ...props.override,
      }}
      variant="plain"
      onClick={(event) => likeCampaign(event)}
    >
      {props.liked ? (
        <FavoriteIcon sx={{ color: "red" }} />
      ) : (
        <FavoriteBorder sx={{ color: theme.vars.palette.primary["50"] }} />
      )}
    </IconButton>
  );
};
