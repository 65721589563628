import { URL, RECOMMENDATION_QUERY } from "../constants/gqlQueryConstants";

async function getRecommendations(anilistId) {
  let pageNum = 1;
  let hasNextPage = true;
  const NUM_RECS = 10;

  const recs = [];

  try {
    while (recs.length < NUM_RECS && hasNextPage) {
      const variables_rec = {
        page: pageNum,
        perPage: 10,
        search: anilistId,
      };
      const options_rec = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          query: RECOMMENDATION_QUERY,
          variables: variables_rec,
        }),
      };
      await fetch(URL, options_rec)
        .then((response) => response.json())
        .then((returnData) => {
          hasNextPage = returnData.data.Page.pageInfo.hasNextPage;
          returnData.data.Page.recommendations.forEach((ele) => {
            const adaptation = ele.mediaRecommendation.relations.edges.find(
              (e) =>
                (e?.relationType === "ADAPTATION" ||
                  e?.relationType === "ALTERNATIVE") &&
                e?.node?.type === "ANIME"
            );
            if (
              adaptation === undefined &&
              recs.length < NUM_RECS &&
              !recs.some((e) => e.anilistId === ele.mediaRecommendation.id)
            ) {
              recs.push({
                anilist_id: ele.mediaRecommendation.id,
                name_english: ele.mediaRecommendation.title.english,
                name_romaji: ele.mediaRecommendation.title.romaji,
                image_url: ele.mediaRecommendation.coverImage.extraLarge,
              });
            }
          });
          pageNum++;
        });
    }
    return recs;
  } catch (error) {
    console.error(`Error: ${error}`);
    return recs;
  }
}

export default getRecommendations;
