import React, { useEffect } from "react";
import { useTheme } from "@mui/joy/styles";
import SearchBarAtom from "../atoms/SearchBarAtom";
import gsap from "gsap";
import SplitTextJS from "split-text-js";

export default function SearchPageIntroBlock() {
  const theme = useTheme();
  const [screenWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    const titles = gsap.utils.toArray(".text-wrapper > p");
    const t1 = gsap.timeline({ repeat: -1 });

    titles.forEach((title, index) => {
      const splitTitle = new SplitTextJS(title);
      if (index == 0) {
        t1.from(
          splitTitle.chars,
          {
            opacity: 1,
          },
          "<"
        ).to(
          splitTitle.chars,
          {
            opacity: 0,
            y: -20,
            rotateX: 90,
          },
          "<1"
        );
      } else if (index == titles.length - 1) {
        t1.from(
          splitTitle.chars,
          {
            opacity: 0,
            y: 20,
            rotateX: -90,
          },
          "<"
        ).to(
          splitTitle.chars,
          {
            opacity: 1,
          },
          "<1"
        );
      } else {
        t1.from(
          splitTitle.chars,
          {
            opacity: 0,
            y: 20,
            rotateX: -90,
          },
          "<"
        ).to(
          splitTitle.chars,
          {
            opacity: 0,
            y: -20,
            rotateX: 90,
          },
          "<1"
        );
      }
    });
  });

  return (
    <div style={{ marginBottom: "100px", zIndex: 1 }}>
      <div className="vertical-box" style={{ alignItems: "center" }}>
        <h1
          style={{
            fontSize: "50px",
            textAlign: "center",
            padding: screenWidth > 800 ? "0 30px" : "0 0",
            color: theme.vars.palette.primary["50"],
            zIndex: 1,
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
            transition: "text-shadow 0.5s ease-in-out",
          }}
        >
          Wish it. Flix it.
        </h1>
        <div
          style={{
            whiteSpace: "nowrap",
            textAlign: "center",
            paddingBottom: "15px",
            color: theme.vars.palette.primary["50"],
            marginTop: "10px",
          }}
        >
          <div
            className="horizontal-box"
            style={{
              justifyContent: "center",
              height: "inherit",
            }}
          >
            <h2
              style={{
                fontSize: "20px",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                transition: "text-shadow 0.5s ease-in-out",
              }}
            >
              Animate your favorite
            </h2>
            <div
              className="container"
              style={{
                paddingTop: "15px",
                zIndex: 1,
                textShadow:
                  "0 0 2px rgba(0, 0, 0, 0.8), 0 0 2px rgba(0, 0, 0, 0.8), 0 0 2px rgba(0, 0, 0, 0.8)",
                transition: "text-shadow 0.5s ease-in-out",
              }}
            >
              <div className="text-wrapper">
                <p>&nbsp;Manga</p>
                <p>&nbsp;Webtoon</p>
                <p>&nbsp;Manhua</p>
                <p>&nbsp;Manhwa</p>
                <p>&nbsp;Manga</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SearchBarAtom />
    </div>
  );
}
