import React from "react";
import PillboxButton from "../atoms/PillboxButton";
import Input from "@mui/joy/Input";
import { useTheme } from "@mui/joy/styles";
import { useNavigate } from "react-router-dom";
import { useAlertContext } from "../contexts/AlertProvider";
import { ROOT_URL } from "../../constants/productionConstants";
import { EMAIL_REGEX } from "../../constants/regexConstants";

export default function PasswordResetBlock() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState("");
  const [validEmail, setValidEmail] = React.useState(false);
  const {setAlertState} = useAlertContext();

  function submitForm() {
    if (!validEmail) {
      setAlertState({html: 'Please enter a valid email', visibility: 'visible'})
    } else {
      const route = `${ROOT_URL}/auth/forgot-password-email`;
      fetch(route, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        body: JSON.stringify({ email: email }),
      })
        .then((response) => response.json())
        .then((data) =>
          data.message === "success"
            ? navigate("/check-email-page", {
                state: { email: email, route: "/auth/forgot-password-email" },
              })
            : ""
        ).catch(error => console.error(error));
    }
  }
  function checkEmail(e) {
    setEmail(e);
    if (e !== "") {
      setValidEmail(EMAIL_REGEX.test(e))
    }
  }

  return (
    <div
      className="vertical-box"
      style={{
        justifyContent: "center",
        alignItems: "center",
        top: "15%",
        position: "relative",
        flex: 1,
        padding: "40px 0",
      }}
    >
      <h1 style={{ color: theme.vars.palette.primary["50"], margin: "20px 0" }}>
        Reset Password
      </h1>
      <div style={{ width: "80%", maxWidth: "400px" }}>
        <Input
          placeholder="Email"
          variant="soft"
          data-skip-inverted-colors
          sx={{
            height: "50px",
            marginBottom: "20px",
            borderRadius: "50px",
            boxShadow: "2px 2px 5px black",
            "--Input-focusedHighlight": theme.vars.palette.primary["50"],
          }}
          onChange={(event) => checkEmail(event.target.value)}
        />
        <PillboxButton onClick={() => submitForm()}>
          <h3>Send</h3>
        </PillboxButton>
      </div>
    </div>
  );
}
