import React from "react";
import Input from "@mui/joy/Input";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/joy/IconButton";
import { useTheme } from "@mui/joy/styles";

export default function SearchBarAtom() {
  const theme = useTheme();
  const [search, setSearch] = React.useState("");

  function checkEnterKey(event) {
    if (event.key === "Enter") {
      submitForm();
    }
  }
  function submitForm() {
    if (search != "") {
      window.location.href = `/search-page/?q=${search}`;
    }
  }
  return (
    <div className="horizontal-box" style={{ justifyContent: "center" }}>
      <Input
        placeholder="Search"
        variant="soft"
        data-skip-inverted-colors
        sx={{
          maxWidth: "1000px",
          width: "80%",
          marginRight: "20px",
          borderRadius: "15px",
          boxShadow: "2px 2px 5px black",
          "--Input-focusedHighlight": theme.vars.palette.primary["50"],
        }}
        onKeyDown={checkEnterKey}
        onChange={(event) => setSearch(event.target.value.trim())}
      />
      <IconButton
        className="lift-up"
        variant="solid"
        sx={{
          borderRadius: "50%",
          boxShadow: "2px 2px 5px black",
          backgroundColor: theme.vars.palette.primary["150"],
          "&:hover": {
            backgroundColor: theme.vars.palette.primary["150"],
          },
        }}
        onClick={submitForm}
      >
        <SearchIcon
          sx={{
            color: theme.vars.palette.primary["50"],
          }}
        />
      </IconButton>
    </div>
  );
}
