import * as React from "react";
import CampaignCardOverlayBlock from "./CampaignCardOverlayBlock";

export default function GradientCover(props) {
  const ref = React.useRef(0);
  const handleClick = (e) => {
    e.stopPropagation();
    if (props.isDragging) {
      e.preventDefault();
    }
  };

  return (
    <div
      ref={ref}
      className="responsive-box lift-up"
      style={{
        position: "relative",
        aspectRatio: 7 / 10,
        margin: props.margin,
        boxSizing: "border-box",
        borderRadius: "10px",
        background: `url("/images/stars2.jpg")`,
        backgroundSize: "cover",
      }}
      onClick={handleClick}
    >
      <a 
        href={`/manga-page/${props.data.anilist_id}/${(props.data.name_english ?? props.data.name_romaji)}`} 
        draggable={false}
      >
        <img
          style={{
            borderRadius: "10px",
            boxShadow: "2px 2px 5px black",
            width: "100%",
            height: "100%",
            objectFit: props.data.image_url ? "cover" : "contain",
          }}
          src={props.data.image_url ?? "/images/logo.png"}
          alt="movie"
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            bottom: 0,
            left: 0,
            borderRadius: "10px",
            background:
              "linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0) 150px), linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0) 200px)",
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "95%",
            bottom: "20px",
            left: "2.5%",
          }}
        >
          <CampaignCardOverlayBlock data={props.data} />
        </div>
      </a>
    </div>
  );
}
