import React from "react";
import LinearProgress from "@mui/joy/LinearProgress";
import { useTheme } from "@mui/joy/styles";

const CardProgressBar = ({ likes }) => {
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          justifyContent: "flex-end",
          margin: "0 5px",
        }}
        className="horizontal-box"
      >
        <h3 style={{ color: "#f6cf96", fontSize: "14px" }}>
          {likes} {likes == 1 ? "Like" : "Likes"}
        </h3>
      </div>
      <LinearProgress
        sx={{
          margin: "0 5px",
          color: theme.vars.palette.primary["50"],
          boxShadow: "2px 2px 5px black",
        }}
        determinate
        value={(Math.min(likes, 50000) / 50000) * 100}
      />
    </>
  );
};

export default CardProgressBar;
