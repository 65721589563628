import React from "react";
import NoTransactionsBlock from "./NoTransactionsBlock";
import CampaignFundingDetails from "../blocks/CampaignFundingDetails";
import CoinPurchaseDetails from "../blocks/CoinPurchaseDetails";
import Button from "@mui/joy/Button";
import { useTheme } from "@mui/joy/styles";

export default function TransactionDetailsBox(props) {
  const theme = useTheme();

  const NUM_TRANSACTIONS = props.numTransactions;
  const transactions = [];

  for (let i = 0; i < NUM_TRANSACTIONS; i++) {
    transactions.push(i);
  }

  return (
    <div
      className="vertical-box"
      style={{
        overflow: "auto",
        justifyContent: NUM_TRANSACTIONS === 0 ? "center" : "flex-start",
        flex: "auto",
      }}
    >
      {transactions.length === 0 ? (
        <NoTransactionsBlock />
      ) : (
        transactions.map(function (ele, index) {
          const randomZeroOrOne = Math.round(Math.random());

          if (randomZeroOrOne === 0) {
            return <CampaignFundingDetails key={index} />;
          } else {
            return <CoinPurchaseDetails key={index} />;
          }
        })
      )}

      <Button
        style={{
          alignSelf: "center",
          boxShadow: "2px 2px 5px black",
          backgroundColor: theme.vars.palette.primary["150"],
          borderRadius: "10px",
          margin: "20px",
        }}
      >
        Load More
      </Button>
    </div>
  );
}
