import React from "react";
import { useTheme } from "@mui/joy/styles";
import SearchBar from "./SearchBar";

function IntroductionBlock() {
  const theme = useTheme();
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  window.addEventListener("resize", () => setScreenWidth(window.innerWidth));

  return (
    <div
      className="vertical-box"
      style={{
        padding: "20px",
        height: "77vh",
        justifyContent: "center",
        alignItems: "stretch",
      }}
    >
      <SearchBar />
    </div>
  );
}

export default IntroductionBlock;
